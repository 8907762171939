// src/components/Finance/TransactionApprovalModal.js

import React, { useState } from 'react';
import { apiService } from '../../../services/apiService';
import './ManualTransactions.css';

const TransactionApprovalModal = ({ transaction, onClose, onApprove }) => {
    const [transactionDate, setTransactionDate] = useState(transaction.transactionDate);
    const [transactionRefNum, setTransactionRefNum] = useState(transaction.transactionRefNum);
    const [screenshot, setScreenshot] = useState(null);
    const [error, setError] = useState(null);

    const handleFileChange = (e) => {
        setScreenshot(e.target.files[0]);
    };

    const handleApprove = async () => {
        if (!transactionDate || !transactionRefNum || !screenshot) {
            setError('All fields are required');
            return;
        }

        const formData = new FormData();
        formData.append('transactionDate', transactionDate);
        formData.append('transactionRefNum', transactionRefNum);
        formData.append('file', screenshot);

        try {
            await apiService.upload(`/internal/finance/approve-manual-transaction/${transaction._id}`, formData);
            onApprove(); // Notify parent to refetch transactions
        } catch (error) {
            console.error('Error approving transaction:', error);
            setError('Failed to approve transaction. Please try again.');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Approve Transaction</h3>
                {error && <div className="error-message">{error}</div>}
                <div className="form-group">
                    <label>Transaction Date</label>
                    <input
                        type="date"
                        value={new Date(transactionDate).toISOString().substring(0, 10)}
                        onChange={(e) => setTransactionDate(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Transaction Reference Number</label>
                    <input
                        type="text"
                        value={transactionRefNum}
                        onChange={(e) => setTransactionRefNum(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Transaction Screenshot</label>
                    <input type="file" accept="image/*" onChange={handleFileChange} required />
                </div>
                <div className="modal-actions">
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="approve-button" onClick={handleApprove}>
                        Approve
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TransactionApprovalModal;
