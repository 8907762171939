import React, { useState, useEffect } from 'react';
import { apiService } from '../../../services/apiService';
import { toast } from 'react-hot-toast';

const TravelAgentActivationModal = ({ travelAgentId, onClose }) => {
    const [travelAgent, setTravelAgent] = useState(null);
    const [states, setStates] = useState([]);
    const [rejectionReason, setRejectionReason] = useState('');
    const [showRejectionTooltip, setShowRejectionTooltip] = useState(false);
    const [showApprovalTooltip, setShowApprovalTooltip] = useState(false);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest(".travel-agent-activation-rejection-tooltip"))
                return;

            if (showRejectionTooltip) {
                setShowRejectionTooltip(null); // Hide tooltip when clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showRejectionTooltip]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest(".travel-agent-activation-approval-tooltip"))
                return;

            if (showApprovalTooltip) {
                setShowApprovalTooltip(null); // Hide tooltip when clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showApprovalTooltip]);

    // Fetch travel agent details and state list
    useEffect(() => {
        const fetchTravelAgentDetails = async () => {
            try {
                const response = await apiService.get(`/internal/travel-agents/${travelAgentId}?includeBusinessProofs=true`);
                setTravelAgent(response.travelAgent);
            } catch (error) {
                console.error('Error fetching travel agent details:', error);
            }
        };

        const fetchStates = async () => {
            try {
                const statesData = await apiService.get('/travelAgent/public/states');
                setStates(statesData.states);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchTravelAgentDetails();
        fetchStates();
    }, [travelAgentId]);

    const handleApprove = async () => {
        const travelAgentData = {
            businessName: travelAgent.businessName,
            pointOfContactName: travelAgent.pointOfContactName,
            pointOfContactNumber: travelAgent.pointOfContactNumber,
            address: travelAgent.address,
            city: travelAgent.city,
            stateId: travelAgent.stateId,
            gstin: travelAgent.gstin
        };

        const approvePromise = apiService.post(`/internal/admin/travel-agent/approve-activation/${travelAgentId}`, travelAgentData);
        toast.promise(approvePromise, {
            loading: 'Approving...',
            success: 'Approve successful!',
            error: 'Approve failed! Try again.'
        });

        approvePromise.then(() => {
            onClose();
        }).catch(() => {
            toast.error('Error approving travel agent!');
        })
    };

    const handleReject = async () => {
        if (!rejectionReason) {
            alert('Please provide a rejection reason.');
            return;
        }

        const rejectPromise = apiService.post(`/internal/admin/travel-agent/reject-activation/${travelAgentId}`, { rejectionReason });

        toast.promise(rejectPromise, {
            loading: "Rejecting activation...",
            success: "Activation rejected",
            error: "Error rejecting activation! Try Again!"
        })

        rejectPromise.then(() => {
            onClose();
        }).catch((error) => {
            toast.error("Error rejecting activation! Try Again!");
            console.error('Error rejecting travel agent:', error);
        })

        onClose();

    };

    if (!travelAgent) return <div>Loading...</div>;

    return (
        <div className="modal-overlay">
            <div className="travel-agent-activation-modal-content">
                <div className="modal-header">
                    <h3>Activate Travel Agent</h3>
                    <button onClick={onClose} className="close-button">Close</button>
                </div>

                <div className="modal-body">
                    <div className="travel-agent-activation-left-section">
                        <h4>Business Proof Documents</h4>
                        <div className="documents">
                            {travelAgent.businessProofFileKeys.map((doc, index) => (
                                <div key={index} className="document">
                                    {doc.mimeType === 'application/pdf' ? (
                                        <object
                                            data={doc.fileUrl}
                                            type="application/pdf"
                                            width="100%"
                                            height="600px"
                                        >
                                        </object>
                                    ) : (
                                        <img
                                            src={doc.fileUrl}
                                            alt={`Document ${index + 1}`}
                                            width="100%"
                                            height="auto"
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="travel-agent-activation-right-section">
                        <form>
                            <div className="form-group">
                                <label>Business Name</label>
                                <input
                                    type="text"
                                    value={travelAgent.businessName}
                                    onChange={(e) => setTravelAgent({ ...travelAgent, businessName: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>Point of Contact</label>
                                <input
                                    type="text"
                                    value={travelAgent.pointOfContactName}
                                    onChange={(e) => setTravelAgent({ ...travelAgent, pointOfContactName: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>Point of Contact Number</label>
                                <input
                                    type="text"
                                    value={travelAgent.pointOfContactNumber}
                                    onChange={(e) => setTravelAgent({ ...travelAgent, pointOfContactNumber: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>Address</label>
                                <input
                                    type="text"
                                    value={travelAgent.address}
                                    onChange={(e) => setTravelAgent({ ...travelAgent, address: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>City</label>
                                <input
                                    type="text"
                                    value={travelAgent.city}
                                    onChange={(e) => setTravelAgent({ ...travelAgent, city: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <label>State</label>
                                <select
                                    value={travelAgent.state ? travelAgent.state._id : ''}
                                    onChange={(e) => setTravelAgent({ ...travelAgent, stateId: e.target.value })}
                                >
                                    <option value="" disabled>Select State</option>
                                    {states.map((state) => (
                                        <option key={state._id} value={state._id}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {travelAgent.gstin && (<div className="form-group">
                                <label>GSTIN</label>
                                <input
                                    type="text"
                                    value={travelAgent.gstin}
                                    onChange={(e) => setTravelAgent({ ...travelAgent, gstin: e.target.value })}
                                />
                            </div>)}
                        </form>
                        <div className="travel-agent-activation-modal-actions">
                            {showApprovalTooltip && (
                                <div className="travel-agent-activation-approval-tooltip">
                                    <p>Are you sure you want to approve this travel agent?</p>
                                    <div className="travel-agent-activation-approval-tooltip-buttons">
                                        <button className="btn-yes" onClick={handleApprove}>Yes</button>
                                        <button className="btn-no" onClick={() => setShowApprovalTooltip(null)}>No</button>
                                    </div>
                                </div>
                            )}
                            {showRejectionTooltip && (
                                <div className="travel-agent-activation-rejection-tooltip">
                                    <label>Rejection Reason</label>
                                    <textarea
                                        value={rejectionReason}
                                        rows={4}
                                        onChange={(e) => setRejectionReason(e.target.value)}
                                    />
                                    <button onClick={handleReject} className="travel-agent-activation-submit-rejection-button">Submit Rejection</button>
                                </div>
                            )}
                            <div className="travel-agent-activation-modal-buttons">
                                <button className="travel-agent-activation-approve-button" onClick={() => setShowApprovalTooltip(true)}>Approve</button>
                                <button
                                    className="travel-agent-activation-reject-button"
                                    onClick={() => setShowRejectionTooltip(!showRejectionTooltip)}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TravelAgentActivationModal;
