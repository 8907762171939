// src/components/MasterData/VisaTypes.js

import React, { useEffect, useState } from 'react';
import { apiService } from '../../../services/apiService';
import { FaEdit, FaTrash, FaPlus, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
// import CreateVisaTypeModal from './CreateVisaTypeModal';

const DocumentChecklists = () => {
    const [visaTypes, setVisaTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const pageSize = 10;

    useEffect(() => {
        fetchVisaTypes();
    }, [currentPage, searchTerm]);

    const fetchVisaTypes = () => {
        const url = searchTerm
            ? `/internal/visa-types/search?search=${searchTerm}&page=${currentPage}&count=${pageSize}`
            : `/internal/visa-types?page=${currentPage}&count=${pageSize}`;

        apiService.get(url)
            .then(responseData => {
                setVisaTypes(responseData.visaTypes);
                setTotalCount(responseData.totalCount);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };

    const handleEdit = (visaType) => {
        navigate(`/master-data/document-checklist/${visaType._id}`)
    };

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / pageSize)) {
            setCurrentPage(currentPage + 1);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="main-container">
            <div className="main-header">
                <div>
                    <h2>Document Checklists</h2>
                    <input
                        type="text"
                        placeholder="Search visa types..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                        className="search-input"
                    />
                </div>

            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Country</th>
                        <th>Visa Type</th>
                        <th>Process Category</th>
                        <th>Purpose</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {visaTypes.map((visaType) => (
                        <tr key={visaType._id}>
                            <td>{visaType.countryId.name}</td>
                            <td>{visaType.visaName}</td>
                            <td>{visaType.visaProcessCategory}</td>
                            <td>{visaType.visaPurpose}</td>
                            <td>
                                <FaEdit className="action-icon" onClick={() => handleEdit(visaType)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination-controls">
                <button
                    className="pagination-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <FaChevronLeft /> Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(totalCount / pageSize)}</span>
                <button
                    className="pagination-button"
                    onClick={handleNextPage}
                    disabled={currentPage === Math.ceil(totalCount / pageSize)}
                >
                    Next <FaChevronRight />
                </button>
            </div>

        </div>
    );
};

export default DocumentChecklists;
