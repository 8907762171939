// src/components/MasterData/CreateVisaTypeModal.js

import React, { useState, useEffect } from 'react';
import { apiService } from '../../../services/apiService';

const CreateVisaTypeModal = ({ onClose, onCreate, onEdit, isEditing, initialData }) => {
    const [countries, setCountries] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const [countryId, setCountryId] = useState('');
    const [visaName, setVisaName] = useState('');
    const [visaProcessCategory, setVisaProcessCategory] = useState('');
    const [visaPurpose, setVisaPurpose] = useState('');
    const [visaValidityAtConsulatesDiscretion, setVisaValidityAtConsulatesDiscretion] = useState(false);
    const [visaValidFor, setVisaValidFor] = useState('');
    const [maxVisaValidFor, setMaxVisaValidFor] = useState('');
    const [durationOfStayAtConsulatesDiscretion, setDurationOfStayAtConsulatesDiscretion] = useState(false);
    const [durationOfStay, setDurationOfStay] = useState('');
    const [maxDurationOfStay, setMaxDurationOfStay] = useState('');
    const [numberOfEntriesAtConsulatesDiscretion, setNumberOfEntriesAtConsulatesDiscretion] = useState(false);
    const [numberOfEntries, setNumberOfEntries] = useState('Single');
    const [visaFeesCurrency, setVisaFeesCurrency] = useState('');
    const [visaFees, setVisaFees] = useState('');
    const [vacFeesCurrency, setVacFeesCurrency] = useState('');
    const [vacFees, setVacFees] = useState('');
    const [processingTime, setProcessingTime] = useState('');
    const [automationAvailable, setAutomationAvailable] = useState(false);
    const [submissionAt, setSubmissionAt] = useState('Embassy/Consulate');
    const [visaWorkflowId, setVisaWorkflowId] = useState('');
    const [serviceCharges, setServiceCharges] = useState('');
    const [packagePrice, setPackagePrice] = useState(null);

    const processCategories = ['eVisa', 'Sticker Visa - Physical Submission', 'eVisa - Physical Submission', 'Sticker Visa - Online Submission'];
    const purposes = ['Tourist', 'Business'];

    useEffect(() => {
        fetchDropdownData();

        if (isEditing && initialData) {
            setCountryId(initialData.countryId);
            setVisaName(initialData.visaName);
            setVisaProcessCategory(initialData.visaProcessCategory);
            setVisaPurpose(initialData.visaPurpose);
            // setSubPurposeId(initialData.visaPurposeId._id);
            setVisaValidityAtConsulatesDiscretion(initialData.visaValidityAtConsulatesDiscretion);
            setVisaValidFor(initialData.visaValidFor);
            setMaxVisaValidFor(initialData.maxVisaValidFor);
            setDurationOfStayAtConsulatesDiscretion(initialData.durationOfStayAtConsulatesDiscretion);
            setDurationOfStay(initialData.durationOfStay);
            setMaxDurationOfStay(initialData.maxDurationOfStay);
            setNumberOfEntriesAtConsulatesDiscretion(initialData.numberOfEntriesAtConsulatesDiscretion);
            setNumberOfEntries(initialData.numberOfEntries);
            setVisaFeesCurrency(initialData.visaFeesCurrency);
            setVisaFees(initialData.visaFees);
            setProcessingTime(initialData.processingTime);
            setAutomationAvailable(initialData.automationAvailable);
            setSubmissionAt(initialData.submissionAt);
            setVisaWorkflowId(initialData.visaWorkflowId);
            setServiceCharges(initialData.serviceCharges);
            setPackagePrice(initialData.packagePrice);
        }
    }, [isEditing, initialData]);

    // useEffect(() => {
    //     if (visaPurpose) {
    //         const filteredSubPurposes = purposes.filter(
    //             (purpose) => purpose.purpose === visaPurpose
    //         );
    //         setSubPurposes(filteredSubPurposes);
    //     } else {
    //         setSubPurposes([]);
    //     }
    // }, [visaPurpose, purposes]);

    const fetchDropdownData = async () => {
        try {
            let countriesData, workflowsData, currenciesData;
            Promise.all([apiService.get('/internal/countries'), apiService.get('/internal/workflows'), apiService.get('/internal/currencies')]).then(result => {
                countriesData = result[0];
                workflowsData = result[1];
                currenciesData = result[2];
                setCountries(countriesData.countries);
                setWorkflows(workflowsData.workflows);
                setCurrencies(currenciesData.currencies);
            });
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const visaTypeData = {
            countryId: countryId._id || countryId,
            visaName,
            visaProcessCategory: visaProcessCategory._id || visaProcessCategory,
            // visaPurposeId: subPurposeId,
            visaPurpose,
            visaValidityAtConsulatesDiscretion,
            visaValidFor: visaValidityAtConsulatesDiscretion ? undefined : visaValidFor,
            maxVisaValidFor: visaValidityAtConsulatesDiscretion ? maxVisaValidFor : undefined,
            durationOfStayAtConsulatesDiscretion,
            durationOfStay: durationOfStayAtConsulatesDiscretion ? undefined : durationOfStay,
            maxDurationOfStay: durationOfStayAtConsulatesDiscretion ? maxDurationOfStay : undefined,
            numberOfEntriesAtConsulatesDiscretion,
            numberOfEntries: numberOfEntriesAtConsulatesDiscretion ? undefined : numberOfEntries,
            visaFeesCurrency: visaFeesCurrency._id || visaFeesCurrency,
            visaFees,
            vacFeesCurrency: vacFeesCurrency ? vacFeesCurrency._id ? vacFeesCurrency._id : vacFeesCurrency : undefined,
            vacFees: vacFees || undefined,
            serviceCharges,
            packagePrice: packagePrice || undefined,
            processingTime,
            automationAvailable,
            submissionAt,
            visaWorkflowId: visaWorkflowId._id || visaWorkflowId,
        };
        if (isEditing) {
            onEdit(visaTypeData);
        } else {
            onCreate(visaTypeData);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{isEditing ? 'Edit Visa Type' : 'Create New Visa Type'}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Country</label>
                        <select value={countryId._id} onChange={(e) => setCountryId(e.target.value)} required>
                            <option value="" disabled>Select Country</option>
                            {countries.map((country) => (
                                <option key={country._id} value={country._id}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Visa Name</label>
                        <input
                            type="text"
                            value={visaName}
                            onChange={(e) => setVisaName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Process Category</label>
                        <select value={visaProcessCategory} onChange={(e) => setVisaProcessCategory(e.target.value)} required>
                            <option value="" disabled>Select Process Category</option>
                            {processCategories.map((category) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Purpose</label>
                        <select value={visaPurpose} onChange={(e) => setVisaPurpose(e.target.value)} required>
                            <option value="" disabled>Select Purpose</option>
                            {purposes.map((purpose) => (
                                <option key={purpose} value={purpose}>
                                    {purpose}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* {visaPurpose && (
                        <div className="form-group">
                            <label>Sub Purpose</label>
                            <select value={subPurposeId} onChange={(e) => setSubPurposeId(e.target.value)} required>
                                <option value="" disabled>Select Sub Purpose</option>
                                {subPurposes.map((subPurpose) => (
                                    <option key={subPurpose._id} value={subPurpose._id}>
                                        {subPurpose.subPurpose}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )} */}
                    <div className="form-group">
                        <label>Visa Validity At Consulates Discretion</label>
                        <input
                            type="checkbox"
                            checked={visaValidityAtConsulatesDiscretion}
                            onChange={(e) => setVisaValidityAtConsulatesDiscretion(e.target.checked)}
                        />
                    </div>
                    {!visaValidityAtConsulatesDiscretion && (

                        <div className="form-group">
                            <label>Visa Valid For (Days)</label>
                            <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={visaValidFor}
                                onChange={(e) => setVisaValidFor(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    {visaValidityAtConsulatesDiscretion && (
                        <div className="form-group">
                            <label>Max Visa Valid For (Days)</label>
                            <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={maxVisaValidFor}
                                onChange={(e) => setMaxVisaValidFor(e.target.value)}
                                required
                            />
                        </div>

                    )}
                    <div className="form-group">
                        <label>Duration Of Stay At Consulates Discretion</label>
                        <input
                            type="checkbox"
                            checked={durationOfStayAtConsulatesDiscretion}
                            onChange={(e) => setDurationOfStayAtConsulatesDiscretion(e.target.checked)}
                        />
                    </div>
                    {!durationOfStayAtConsulatesDiscretion && (

                        <div className="form-group">
                            <label>Duration Of Stay (Days)</label>
                            <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={durationOfStay}
                                onChange={(e) => setDurationOfStay(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    {durationOfStayAtConsulatesDiscretion && (
                        <div className="form-group">
                            <label>Max Duration Of Stay (Days)</label>
                            <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={maxDurationOfStay}
                                onChange={(e) => setMaxDurationOfStay(e.target.value)}
                                required
                            />
                        </div>

                    )}
                    <div className="form-group">
                        <label>Number Of Entries At Consulates Discretion</label>
                        <input
                            type="checkbox"
                            checked={numberOfEntriesAtConsulatesDiscretion}
                            onChange={(e) => setNumberOfEntriesAtConsulatesDiscretion(e.target.checked)}
                        />
                    </div>
                    {!numberOfEntriesAtConsulatesDiscretion && (
                        <div className="form-group">
                            <label>Number Of Entries</label>
                            <select value={numberOfEntries} onChange={(e) => setNumberOfEntries(e.target.value)} required>
                                <option value="Single">Single</option>
                                <option value="Double">Double</option>
                                <option value="Multiple">Multiple</option>
                            </select>
                        </div>
                    )}
                    <div className="form-group">
                        <label>Visa Fees Currency</label>
                        <select value={visaFeesCurrency} onChange={(e) => setVisaFeesCurrency(e.target.value)} required>
                            <option value="" disabled>Select Currency</option>
                            {currencies.map((currency) => (
                                <option key={currency._id} value={currency._id}>
                                    {currency.isoCode}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Visa Fees</label>
                        <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            inputmode="numeric"
                            value={visaFees}
                            onChange={(e) => setVisaFees(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Application Center Fees Currency</label>
                        <select value={vacFeesCurrency} onChange={(e) => setVacFeesCurrency(e.target.value)}>
                            <option value="" disabled>Select Currency</option>
                            {currencies.map((currency) => (
                                <option key={currency._id} value={currency._id}>
                                    {currency.isoCode}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Application Center Fees</label>
                        <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={vacFees}
                            onChange={(e) => setVacFees(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>VC Service Charges</label>
                        <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={serviceCharges}
                            onChange={(e) => setServiceCharges(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Package Price (optional)</label>
                        <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={packagePrice}
                            onChange={(e) => setPackagePrice(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Processing Time (Days)</label>
                        <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={processingTime}
                            onChange={(e) => setProcessingTime(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Automation Available</label>
                        <input
                            type="checkbox"
                            checked={automationAvailable}
                            onChange={(e) => setAutomationAvailable(e.target.checked)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Submission At</label>
                        <select value={submissionAt} onChange={(e) => setSubmissionAt(e.target.value)} required>
                            <option value="Embassy/Consulate">Embassy/Consulate</option>
                            <option value="Online">Online</option>
                            <option value="Visa Application Centre">Visa Application Centre</option>
                            <option value="Online + Physical">Online + Physical</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Visa Workflow</label>
                        <select value={visaWorkflowId} onChange={(e) => setVisaWorkflowId(e.target.value)} required>
                            <option value="" disabled>Select Workflow</option>
                            {workflows.map((workflow) => (
                                <option key={workflow._id} value={workflow._id}>
                                    {workflow.workflowName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="modal-actions">
                        <button type="button" onClick={onClose} className="cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="create-button">
                            {isEditing ? 'Save Changes' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateVisaTypeModal;
