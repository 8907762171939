// src/components/MasterData/Workflows.js

import React, { useEffect, useState } from 'react';
import { apiService } from '../../../services/apiService';
import { FaEdit, FaTrash, FaPlus, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import CreateWorkflowModal from './CreateWorkflowModal';

const Workflows = () => {
    const [workflows, setWorkflows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentWorkflow, setCurrentWorkflow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const pageSize = 10;

    useEffect(() => {
        fetchWorkflows();
    }, [currentPage, searchTerm]);

    const fetchWorkflows = () => {
        const url = searchTerm
            ? `/internal/workflows/search?search=${searchTerm}&page=${currentPage}&count=${pageSize}`
            : `/internal/workflows?page=${currentPage}&count=${pageSize}`;

        apiService.get(url)
            .then(responseData => {
                setWorkflows(responseData.workflows);
                setTotalCount(responseData.totalCount);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };

    const handleEdit = (workflow) => {
        setCurrentWorkflow(workflow);
        setIsEditing(true);
        setIsModalOpen(true);
    };

    const handleDelete = (workflow) => {
        if (window.confirm(`Are you sure you want to delete ${workflow.workflowName}?`)) {
            apiService.delete(`/internal/workflows/${workflow._id}`)
                .then(() => {
                    fetchWorkflows(); // Refresh the list after deleting a workflow
                })
                .catch(error => {
                    console.error('Error deleting workflow:', error);
                    alert('Failed to delete workflow.');
                });
        }
    };

    const handleCreateNewWorkflow = (newWorkflow) => {
        apiService.post('/internal/workflows', newWorkflow)
            .then(() => {
                fetchWorkflows(); // Refresh the list after adding a new workflow
                alert('Workflow saved!');
                setIsModalOpen(false); // Close the modal after success
            })
            .catch(error => {
                console.error('Error creating workflow:', error);
                alert('Failed to create workflow.');
            });
    };

    const handleEditWorkflow = (updatedWorkflow) => {
        apiService.put(`/internal/workflows/${currentWorkflow._id}`, updatedWorkflow)
            .then(() => {
                fetchWorkflows(); // Refresh the list after editing the workflow
                alert('Workflow saved!');
                setIsModalOpen(false); // Close the modal after success
                setIsEditing(false);
                setCurrentWorkflow(null);
            })
            .catch(error => {
                console.error('Error updating workflow:', error);
                alert('Failed to update workflow.');
            });
    };

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / pageSize)) {
            setCurrentPage(currentPage + 1);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="main-container">
            <div className="main-header">
                <div>
                    <h2>Workflows</h2>
                    <input
                        type="text"
                        placeholder="Search workflows..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                        className="search-input"
                    />
                </div>
                <button className="create-button" onClick={() => {
                    setIsEditing(false);
                    setIsModalOpen(true);
                }}>
                    <FaPlus /> Create New Workflow
                </button>
            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Workflow Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {workflows.map((workflow) => (
                        <tr key={workflow._id}>
                            <td>{workflow.workflowName}</td>
                            <td>
                                <FaEdit className="action-icon" onClick={() => handleEdit(workflow)} />
                                {/* <FaTrash className="action-icon" onClick={() => handleDelete(workflow)} /> */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination-controls">
                <button
                    className="pagination-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <FaChevronLeft /> Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(totalCount / pageSize)}</span>
                <button
                    className="pagination-button"
                    onClick={handleNextPage}
                    disabled={currentPage === Math.ceil(totalCount / pageSize)}
                >
                    Next <FaChevronRight />
                </button>
            </div>

            {isModalOpen && (
                <CreateWorkflowModal
                    onClose={() => setIsModalOpen(false)}
                    onCreate={handleCreateNewWorkflow}
                    onEdit={handleEditWorkflow}
                    isEditing={isEditing}
                    initialData={currentWorkflow}
                />
            )}
        </div>
    );
};

export default Workflows;
