// src/components/MasterData/CreateWorkflowModal.js

import React, { useState, useEffect } from 'react';

const CreateWorkflowModal = ({ onClose, onCreate, onEdit, isEditing, initialData }) => {
    const [workflowName, setWorkflowName] = useState('');
    const [workflowJson, setWorkflowJson] = useState('');

    useEffect(() => {
        if (isEditing && initialData) {
            setWorkflowName(initialData.workflowName);
            setWorkflowJson(JSON.stringify(initialData.workflowJson, null, 2)); // Format JSON with indentation
        }
    }, [isEditing, initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let parsedWorkflowJson;
        try {
            parsedWorkflowJson = JSON.parse(workflowJson);
        } catch (e) {
            alert("Invalid JSON");
            return;
        }
        const workflowData = { workflowName, workflowJson: parsedWorkflowJson };
        if (isEditing) {
            onEdit(workflowData);
        } else {
            onCreate(workflowData);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{isEditing ? 'Edit Workflow' : 'Create New Workflow'}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Workflow Name</label>
                        <input
                            type="text"
                            value={workflowName}
                            onChange={(e) => setWorkflowName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Workflow JSON</label>
                        <textarea
                            value={workflowJson}
                            onChange={(e) => setWorkflowJson(e.target.value)}
                            rows={10}
                            required
                        />
                    </div>
                    <div className="modal-actions">
                        <button type="button" onClick={onClose} className="cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="create-button">
                            {isEditing ? 'Save Changes' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateWorkflowModal;
