export const backendToFrontEndStatusMapping = {
    DOCS_PENDING: "Docs Pending",
    DOCS_UPLOADED: "Docs Uploaded",
    NEEDS_ATTENTION: "Needs Attention",
    DOCS_PENDING_APPROVAL: "Docs Pending Approval",
    SUBMITTED: "Submitted",
    PROCESSING: "Processing",
    VISA_FORM_PENDING: "Visa Form Pending",
    "2ND_LOOK_PENDING": "2nd Look Pending",
    PAYMENT_APPROVAL_PENDING: "Payment Approval Pending",
    READY_FOR_SUBMISSION: "Ready For Submission",
    AWAITING_INVOICE_GENERATION: "Awaiting Invoice",
    VISA_CHECK: "Approved: Visa Check"
}