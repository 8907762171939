import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt, FaPassport, FaUsers, FaChartBar, FaDatabase, FaUserCog, FaSuitcaseRolling, FaMoneyBillWave } from 'react-icons/fa';
import { IoBusiness } from "react-icons/io5";
import '../styles/Sidebar.css';  // Import the CSS file

const Sidebar = () => {
    const [isMasterDataOpen, setMasterDataOpen] = useState(false);
    const [isFinanceOpen, setFinanceOpen] = useState(false);
    const [isTravelAgentsOpen, setTravelAgentsOpen] = useState(false);

    const toggleMasterData = () => {
        setMasterDataOpen(!isMasterDataOpen);
        setFinanceOpen(false);
        setTravelAgentsOpen(false);

    };

    const toggleFinance = () => {
        setFinanceOpen(!isFinanceOpen);
        setMasterDataOpen(false);
        setTravelAgentsOpen(false);
    };

    const toggleTravelAgents = () => {
        setTravelAgentsOpen(!isTravelAgentsOpen);
        setMasterDataOpen(false);
        setFinanceOpen(false);
    };

    return (
        <div className="sidebar">
            <div className="sidebar-logo">
                <img src="/images/sidebar-logo.png" />
            </div>
            <nav>
                <ul>
                    <NavLink className="navlink" to="/" activeClassName="active">
                        <li>
                            <FaTachometerAlt /> Dashboard
                        </li>
                    </NavLink>
                    <NavLink className="navlink" to="/visa-applications" activeClassName="active">
                        <li>
                            <FaPassport /> Visa Applications
                        </li>
                    </NavLink>
                    <li>
                        <div onClick={toggleTravelAgents} className="expandable">
                            <IoBusiness /> Travel Agents
                        </div>
                        {isTravelAgentsOpen && (
                            <ul className="submenu">
                                <NavLink className="navlink" to="/travel-agents/approved-agents" activeClassName="active">
                                    <li>
                                        Approved Agents
                                    </li>
                                </NavLink>
                                <NavLink className="navlink" to="/travel-agents/activation-requested" activeClassName="active">
                                    <li>
                                        Activation Requested
                                    </li>
                                </NavLink>
                                <NavLink className="navlink" to="/travel-agents/unapproved-agents" activeClassName="active">
                                    <li>
                                        Unapproved Agents
                                    </li>
                                </NavLink>
                            </ul>
                        )}
                    </li>
                    <NavLink className="navlink" to="/reports" activeClassName="active">
                        <li>
                            <FaChartBar /> Reports
                        </li>
                    </NavLink>
                    <li>
                        <div onClick={toggleMasterData} className="expandable">
                            <FaDatabase /> Master Data
                        </div>
                        {isMasterDataOpen && (
                            <ul className="submenu">
                                <NavLink className="navlink" to="/master-data/countries" activeClassName="active">
                                    <li>
                                        Countries
                                    </li>
                                </NavLink>
                                <NavLink className="navlink" to="/master-data/states" activeClassName="active">
                                    <li>
                                        States
                                    </li>
                                </NavLink>
                                <NavLink className="navlink" to="/master-data/purposes" activeClassName="active">
                                    <li>
                                        Purposes
                                    </li>
                                </NavLink>
                                <NavLink className="navlink" to="/master-data/visa-types" activeClassName="active">
                                    <li>
                                        Visa Types
                                    </li>
                                </NavLink>
                                <NavLink className="navlink" to="/master-data/document-checklists" activeClassName="active">
                                    <li>
                                        Document Checklists
                                    </li>
                                </NavLink>
                                <NavLink className="navlink" to="/master-data/consulates" activeClassName="active">
                                    <li>
                                        Consulates
                                    </li>
                                </NavLink>
                                <NavLink className="navlink" to="/master-data/workflows" activeClassName="active">
                                    <li>
                                        Workflows
                                    </li>
                                </NavLink>
                            </ul>
                        )}
                    </li>
                    <NavLink className="navlink" to="/user-management" activeClassName="active">
                        <li>
                            <FaUserCog /> User Management
                        </li>
                    </NavLink>
                    <li>
                        <div onClick={toggleFinance} className="expandable">
                            <FaMoneyBillWave /> Finance
                        </div>
                        {isFinanceOpen && (
                            <ul className="submenu">
                                <NavLink className="navlink" to="/finance/manual-transactions" activeClassName="active">
                                    <li>
                                        Manual Transactions
                                    </li>
                                </NavLink>
                            </ul>
                        )}
                    </li>
                </ul>
            </nav>
            <div className="sidebar-logout">
                <NavLink to="/logout" className="logout-button">
                    <img src="/images/sidebar-logout-icon.png" />
                    Logout
                </NavLink>
            </div>
        </div>
    );
};

export default Sidebar;
