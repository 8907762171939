import React, { useState, useEffect } from 'react';
import { apiService } from '../../services/apiService';
import { toast } from 'react-hot-toast';

const VisaDecisionModal = ({ onClose, onDecisionSubmit, visaType, submissionId }) => {
    const [decision, setDecision] = useState('');
    const [reason, setReason] = useState('');
    const [visaCopy, setVisaCopy] = useState(null);
    const [visaValidFrom, setVisaValidFrom] = useState(new Date().toISOString().split('T')[0]);
    const [visaValidUntil, setVisaValidUntil] = useState('');
    const [visaValidFor, setVisaValidFor] = useState(visaType.visaValidFor || '');
    const [approvedDaysOfStay, setApprovedDaysOfStay] = useState(visaType.durationOfStay || '');

    useEffect(() => {
        if (visaValidFor) {
            const defaultValidUntil = new Date(visaValidFrom);
            defaultValidUntil.setDate(defaultValidUntil.getDate() + (visaValidFor - 1));
            setVisaValidUntil(defaultValidUntil.toISOString().split('T')[0]);
        }
    }, [visaValidFor, visaValidFrom]);

    const handleDecisionChange = (e) => {
        setDecision(e.target.value);
        setReason('');
    };

    const handleVisaCopyChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVisaCopy(file);
        }
    };

    const calculateDaysBetweenDates = (startDate, endDate) => {
        // Convert input to Date objects
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Calculate the difference in milliseconds
        const differenceInTime = end - start;

        // Calculate the difference in days (adding 1 to include both dates)
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)) + 1;

        return differenceInDays;
    }

    const handleVisaValidFromChange = (e) => {
        const selectedDate = e.target.value;
        setVisaValidFrom(selectedDate);

        if (visaValidUntil) {
            // Ensure the "Visa Valid Until" date is not earlier than "Visa Valid From"
            if (new Date(visaValidUntil) <= new Date(selectedDate)) {
                // Reset valid until date to the day after the valid from date
                const newValidUntil = new Date(selectedDate);
                newValidUntil.setDate(newValidUntil.getDate() + 1);
                setVisaValidUntil(newValidUntil.toISOString().split('T')[0]);
                setVisaValidFor(1); // Reset to 1 day
            } else {
                // Update "Visa Valid For" based on the new date range
                setVisaValidFor(calculateDaysBetweenDates(selectedDate, visaValidUntil));
            }
        }
    };

    const handleVisaValidUntilChange = (e) => {
        const selectedDate = e.target.value;
        setVisaValidUntil(selectedDate);

        // Automatically update "Visa Valid For" when "Visa Valid Until" changes
        if (selectedDate)
            setVisaValidFor(calculateDaysBetweenDates(visaValidFrom, selectedDate));
        else setVisaValidFor('')
    };

    const handleVisaValidForChange = (e) => {
        let days = parseInt(e.target.value, 10);
        if (days < 1) {
            days = 1; // Prevent less than 1 day
        }
        setVisaValidFor(days);

        if (!days)
            setVisaValidUntil('')
        else {
            const newValidUntil = new Date(visaValidFrom);
            newValidUntil.setDate(newValidUntil.getDate() + (days - 1));
            setVisaValidUntil(newValidUntil.toISOString().split('T')[0]);
        }
    };

    const handleSubmit = async () => {
        if (!decision) {
            toast.error('Please select a visa decision');
            return;
        }

        if (decision === 'Rejected' || decision === 'Withdrawn') {
            if (!reason) {
                toast.error('Please provide a reason for the decision');
                return;
            }
        }

        if (decision === 'Approved' && !visaCopy) {
            toast.error('Please upload a copy of the visa');
            return;
        }

        const formData = new FormData();
        formData.append('decision', decision.toUpperCase());
        formData.append('reason', reason);
        formData.append('visaValidFrom', visaValidFrom);
        formData.append('visaValidUntil', visaValidUntil);
        formData.append('visaValidFor', visaValidFor);
        formData.append('approvedDaysOfStay', approvedDaysOfStay);

        if (visaCopy) {
            formData.append('file', visaCopy);
        }

        try {
            const decisionPromise = apiService.upload(`/internal/visa-application/update-decision/${submissionId}`, formData);

            toast.promise(decisionPromise, {
                loading: 'Updating visa decision',
                success: 'Visa decision updated successfully',
                failure: 'Error updating visa decision'
            });

            decisionPromise.then(() => onDecisionSubmit());

        } catch (error) {
            toast.error('Error updating visa decision');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Update Visa Decision</h3>
                <div className="form-group">
                    <label>Visa Decision</label>
                    <select value={decision} onChange={handleDecisionChange}>
                        <option value="">Select</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Withdrawn">Withdrawn</option>
                    </select>
                </div>

                {decision === 'Approved' && (
                    <>
                        <div className="form-group">
                            <label>Visa Valid From</label>
                            <input
                                type="date"
                                value={visaValidFrom}
                                onChange={handleVisaValidFromChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Visa Valid Until (Date)</label>
                            <input
                                type="date"
                                value={visaValidUntil}
                                onChange={handleVisaValidUntilChange}
                                min={visaValidFrom}
                            />
                        </div>
                        <div className="form-group">
                            <label>Or Visa Valid For (Days)</label>
                            <input
                                type="number"
                                value={visaValidFor}
                                onChange={handleVisaValidForChange}
                                placeholder="Number of days"
                                min="1"
                            />
                        </div>
                        <div className="form-group">
                            <label>Approved Number of Days of Stay</label>
                            <input
                                type="number"
                                value={approvedDaysOfStay}
                                onChange={(e) => setApprovedDaysOfStay(e.target.value)}
                                min="1"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Upload Visa Copy</label>
                            <input type="file" onChange={handleVisaCopyChange} required />
                        </div>
                    </>
                )}

                {(decision === 'Rejected' || decision === 'Withdrawn') && (
                    <div className="form-group">
                        <label>{decision === 'Rejected' ? 'Reason for Rejection' : 'Reason for Withdrawal'}</label>
                        <textarea
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            required
                        />
                    </div>
                )}

                <div className="modal-actions">
                    <button onClick={onClose} className="cancel-button">
                        Cancel
                    </button>
                    <button onClick={handleSubmit} className="submit-button">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VisaDecisionModal;
