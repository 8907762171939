// src/components/UserManagement/CreateUserModal.js

import React, { useState } from 'react';
import { apiService } from '../../services/apiService';
import './UserManagement.css';

const CreateUserModal = ({ onClose, onCreate }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('OPS');
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        const userData = {
            name,
            email,
            phone,
            role,
        };

        try {
            await apiService.post('/internal/admin/user-management/create-user', userData);
            onCreate(); // Notify parent to refresh user list
        } catch (error) {
            setError('Failed to create user. Please try again.');
            console.error('Error creating user:', error);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Create New User</h3>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Role</label>
                        <select value={role} onChange={(e) => setRole(e.target.value)} required>
                            <option value="ADMIN">ADMIN</option>
                            <option value="OPS">OPS</option>
                            <option value="FINANCE">FINANCE</option>
                        </select>
                    </div>
                    <div className="modal-actions">
                        <button type="button" onClick={onClose} className="cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="create-button">
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateUserModal;
