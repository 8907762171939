// src/components/MasterData/CreateCountryModal.js

import React, { useState, useEffect } from 'react';

const CreateCountryModal = ({ onClose, onCreate, onEdit, isEditing, initialData }) => {
    const [name, setName] = useState('');
    const [isoCode, setIsoCode] = useState('');

    useEffect(() => {
        if (isEditing && initialData) {
            setName(initialData.name);
            setIsoCode(initialData.isoCode);
        }
    }, [isEditing, initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const countryData = { name, isoCode };
        if (isEditing) {
            onEdit(countryData);
        } else {
            onCreate(countryData);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{isEditing ? 'Edit Country' : 'Create New Country'}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Country Name</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>ISO Code</label>
                        <input
                            type="text"
                            value={isoCode}
                            onChange={(e) => setIsoCode(e.target.value)}
                            required
                        />
                    </div>
                    <div className="modal-actions">
                        <button type="button" onClick={onClose} className="cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="create-button">
                            {isEditing ? 'Save Changes' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateCountryModal;
