import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { FaChevronLeft, FaChevronRight, FaPlaneDeparture, FaGlobeAfrica, FaPassport, FaArrowLeft, FaRegEdit, FaChevronDown } from 'react-icons/fa';
import { MdGroups, MdOutlineCancel, MdOutlineCheckCircle } from "react-icons/md";
import './VisaApplicationGroup.css';
import { backendToFrontEndStatusMapping } from '../../config/constants';
import { toast } from 'react-hot-toast';

const VisaApplicationGroup = () => {
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;
    const [totalCount, setTotalCount] = useState(0);
    const [visaApplicationGroup, setVisaApplicationGroup] = useState(null);
    const [visaApplications, setVisaApplications] = useState(null);
    const [travelDateFrom, setTravelDateFrom] = useState(new Date());
    const [totalCost, setTotalCost] = useState(0); // State for total cost
    const [individualApplicationCost, setIndividualApplicationCost] = useState(0); // State for total cost
    const [numOfApplications, setNumOfApplications] = useState(0);
    const [travelAgent, setTravelAgent] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0);
    const [travellerNames, setTravellerNames] = useState([]);
    const [editingTraveller, setEditingTraveller] = useState(null);
    const [invoicesGenerated, setInvoicesGenerated] = useState([]);
    const [invoicesPending, setInvoicesPending] = useState([]);
    const [showInvoicesDropdown, setShowInvoicesDropdown] = useState(false);
    const inputRefs = useRef([]);
    const dropdownRef = useRef(null);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.fromPage || 1;

    const fetchVisaApplicationGroupDetails = async () => {
        try {
            const applicationData = await apiService.get(`/internal/visa-application-group/${id}`);
            setVisaApplicationGroup(applicationData);
            setVisaApplications(applicationData.visaApplicationIds);
            setTravelDateFrom(new Date(applicationData.travelDateFrom));
            setTotalCount(applicationData.visaApplicationIds.length);
            setTravellerNames(applicationData.visaApplicationIds.map((app, index) => app.travellerName || `Traveller ${index + 1}`))

            // Fetch total cost of visa application group
            const totalCostData = await apiService.get(`/internal/invoice/calculate-invoice/visa-application-group/${id}`);
            setTotalCost(totalCostData.calculatedInvoices.map(invoice => invoice.totalAmount).reduce((prevValue, currentValue) => prevValue * currentValue, 1) * totalCostData.numOfApplications);

            setIndividualApplicationCost(totalCostData.calculatedInvoices.map(invoice => invoice.totalAmount).reduce((prevValue, currentValue) => prevValue * currentValue, 1));

            setInvoicesGenerated(applicationData.visaApplicationIds.map(app => app.invoices).flat());

            setInvoicesPending(totalCostData.calculatedInvoices.filter(invoice => applicationData.visaApplicationIds.map(app => app.invoices).flat().filter(generatedInvoice => generatedInvoice.type == invoice.invoiceType).length != applicationData.visaApplicationIds.length));
            setNumOfApplications(totalCostData.numOfApplications);
            // Fetch wallet balance of the travel agent
            const travelAgentData = await apiService.get(`/internal/travel-agents/${applicationData.travelAgentId._id}`);
            setTravelAgent(travelAgentData.travelAgent);
            setWalletBalance(travelAgentData.travelAgent.wallet.balance);

        } catch (error) {
            console.error('Error fetching visa application details or cost:', error);
        }
    };

    useEffect(() => {
        fetchVisaApplicationGroupDetails();
    }, [id]);

    useEffect(() => {
        if (!visaApplicationGroup)
            return;

        setTravellerNames(visaApplicationGroup.visaApplicationIds.map((app, index) => app.travellerName || `Traveller ${index + 1}`))
        setVisaApplications(visaApplicationGroup.visaApplicationIds);
    }, [visaApplicationGroup])

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / limit)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleRowClick = (visaApplicationId) => {
        navigate(`/visa-application/${visaApplicationId}`);
    };

    const handleGenerateInvoice = async (invoiceType) => {
        try {
            const generateInvoicePromise = apiService.post(`/internal/invoice/generate-invoice/visa-application-group/${id}`, { visaApplicationGroupId: visaApplicationGroup._id, invoiceType });

            toast.promise(generateInvoicePromise, {
                loading: "Generating invoice...",
                success: "Invoice generated successfully!",
                failure: "Error generating invoice"
            })

            generateInvoicePromise.then(res => {
                fetchVisaApplicationGroupDetails();
            })

        } catch (error) {
            console.error('Error generating invoice:', error);
            alert('Failed to generate invoice.');
        }
    };

    const handleTravellerEdit = (e, visaApplicationId, index) => {
        e.stopPropagation();
        setEditingTraveller(visaApplicationId);
        setTimeout(() => {
            inputRefs.current[index].focus()
            inputRefs.current[index].select()
        })
    }

    const handleTravellerInputKeyDown = (e, visaApplicationId, index) => {
        if (e.key === 'Enter') {
            setEditingTraveller(null);
            saveTravellerName(visaApplicationId, index);
        } else if (e.key === 'Escape') {
            setEditingTraveller(null);
        }
    }

    const handleTravellerEditSave = (e, visaApplicationId, index) => {
        e.stopPropagation();
        setEditingTraveller(null);
        saveTravellerName(visaApplicationId, index);
    }

    const saveTravellerName = (visaApplicationId, index) => {
        if (!travellerNames[index]) {
            toast.error("Enter traveller name");
            return;
        }
        const updateTravellerPromise = apiService.put(`/internal/visa-application/update-traveller/${visaApplicationId}`, { travellerName: travellerNames[index] });

        toast.promise(updateTravellerPromise, {
            loading: "Updating traveller name...",
            success: "Traveller name updated",
            error: "Error updating traveller name"
        });

        updateTravellerPromise.then((res) => {
            setVisaApplicationGroup(res.visaApplicationGroup);
        }).catch((err) => {
            toast.error("Error updating traveller name");
        })
    }

    const handleTravellerEditCancel = (e) => {
        e.stopPropagation();
        setEditingTraveller(null);
    }

    const handleTravellerInputClick = (e) => {
        e.stopPropagation();
    }

    const handleTravellerInputChange = (e, index) => {
        const inputText = e.target.value;
        const newTravellerNames = [...travellerNames];
        newTravellerNames[index] = inputText;
        setTravellerNames(newTravellerNames);
    };

    const handleDownloadVisaApplicationInvoice = async (e, visaApplicationId) => {

        e.preventDefault();
        e.stopPropagation();

        try {
            const response = await apiService.get(`/internal/invoice/generate-pdf-invoice/visa-application-id/${visaApplicationId}`, {
                responseType: 'blob'
            });

            const blob = new Blob([response], { type: 'application/pdf' });

            // Create a URL for the Blob
            const fileURL = URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(fileURL, '_blank');
        } catch (error) {
            toast.error('Error downloading the invoice');
            console.error('Error downloading invoice:', error);
        }
    }

    const handleDownloadVisaApplicationGroupInvoice = async () => {


        try {
            const response = await apiService.get(`/internal/invoice/generate-pdf-invoice/visa-application-group-id/${visaApplicationGroup._id}`, {
                responseType: 'blob'
            });

            const blob = new Blob([response], { type: 'application/pdf' });

            // Create a URL for the Blob
            const fileURL = URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(fileURL, '_blank');
        } catch (error) {
            toast.error('Error downloading the invoice');
            console.error('Error downloading invoice:', error);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowInvoicesDropdown(false);
                setShowInvoicesDropdown(false);
            }
        };

        // Attach event listener to detect clicks outside of the dropdown
        if (showInvoicesDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener when the component unmounts or when the dropdown is closed
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showInvoicesDropdown]);

    const toggleDropdown = () => {
        setShowInvoicesDropdown(!showInvoicesDropdown); // Toggle dropdown visibility
        setShowInvoicesDropdown(!showInvoicesDropdown); // Toggle dropdown visibility
    };

    if (!visaApplicationGroup) {
        return <div>Loading...</div>;
    }

    return (
        <div className="main-container">
            <span className="back-button" onClick={() => navigate(`/visa-applications?page=${fromPage}`)}>
                <FaArrowLeft /> Back to Applications
            </span>

            <div className="visa-application-header">
                <div className="visa-application-header-top-pane">
                    <h2>Details</h2>
                    <div className="visa-application-status-div">
                        <label>Status</label>
                        <div className="visa-application-status-indicator status-color-customer-pending">
                            {backendToFrontEndStatusMapping[visaApplicationGroup.internalStatus]}
                        </div>
                    </div>
                </div>
                <div className="visa-application-header-details">
                    <div className="visa-application-details">
                        <div className="visa-application-details__item">
                            <label>Travel Agent</label>
                            <span className="visa-application-details__item-value">{travelAgent.businessName}</span>
                        </div>
                        <div className="visa-application-details__item">
                            <label>Visa Type</label>
                            <span className="visa-application-details__item-value">{visaApplicationGroup.visaTypeId.visaName}</span>
                        </div>
                        <div className="visa-application-details__item">
                            <label>Group Name</label>
                            <span className="visa-application-details__item-value">{visaApplicationGroup.groupName}</span>
                        </div>
                        <div className="visa-application-details__item">
                            <label>Country</label>
                            <span className="visa-application-details__item-value">{visaApplicationGroup.visaTypeId.countryId.name}</span>
                        </div>
                        <div className="visa-application-details__item">
                            <label>Travel Date</label>
                            <span className="visa-application-details__item-value">{new Date(visaApplicationGroup.travelDateFrom).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, '-')}</span>
                        </div>

                    </div>
                    <div className="visa-application-cost-details">
                        <div className="visa-application-details__item">
                            <label>Total Cost</label>
                            <span className="visa-application-details__item-value">₹{totalCost.toLocaleString()}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="visa-application-actions">
                {invoicesGenerated.length ? <span className='visa-application-download-button' onClick={handleDownloadVisaApplicationGroupInvoice}>Download Invoice</span> : ''}
                {invoicesPending.length ? <div ref={dropdownRef}>
                    <div className="action-dropdown">
                        <button className="action-dropdown-button" onClick={toggleDropdown}>
                            Generate Invoices <FaChevronDown />
                        </button>
                        {showInvoicesDropdown && (
                            <ul className="action-dropdown-menu">
                                {invoicesPending.map((invoice) => (
                                    <li key={invoice.invoiceType} onClick={invoice.totalAmount * numOfApplications < walletBalance && travelAgent.approved ? () => handleGenerateInvoice(invoice.invoiceType) : () => { return }}>
                                        {!travelAgent.approved ? invoice.invoiceName + ' (Travel Agent Not Approved)' : invoice.totalAmount * numOfApplications < walletBalance ? invoice.invoiceName : invoice.invoiceName + ' (Insufficient Balance)'}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div> : ''}
            </div>
            <div className="table-wrapper">
                <table className="data-table">
                    <thead>
                        <tr>
                            <th>Traveller Name</th>
                            <th>Application Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            visaApplications.slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit).map((app, index) => (
                                <tr key={app._id} onClick={() => handleRowClick(app._id)}>
                                    <td className="table-td-center">
                                        {editingTraveller == app._id ?
                                            <>
                                                <input
                                                    ref={(el) => inputRefs.current[index] = el}
                                                    type="text"
                                                    className="visa-application-traveller-input"
                                                    value={travellerNames[index]}
                                                    onClick={handleTravellerInputClick}
                                                    onChange={(e) => handleTravellerInputChange(e, index)}
                                                    onKeyDown={(e) => handleTravellerInputKeyDown(e, app._id, index)}
                                                />
                                                <MdOutlineCheckCircle className="visa-application-traveller-edit-save" onClick={(e) => handleTravellerEditSave(e, app._id, index)} />
                                                <MdOutlineCancel className="visa-application-traveller-edit-cancel" onClick={(e) => handleTravellerEditCancel(e)} />

                                            </>
                                            : <>
                                                {(app.travellerName || `Traveller ${index + 1}`)}
                                                <FaRegEdit className="visa-application-traveller-edit" onClick={(e) => handleTravellerEdit(e, app._id, index)} />
                                            </>
                                        }
                                    </td>
                                    <td className="table-td-center">{backendToFrontEndStatusMapping[app.internalStatus]}</td>
                                    <td>{app.invoices?.length ? <span className='visa-application-download-link' onClick={(e) => handleDownloadVisaApplicationInvoice(e, app._id)}>Download Invoice</span> : ''}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div className="pagination-controls">
                <button
                    className="pagination-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <FaChevronLeft /> Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(totalCount / limit)}</span>
                <button
                    className="pagination-button"
                    onClick={handleNextPage}
                    disabled={currentPage === Math.ceil(totalCount / limit)}
                >
                    Next <FaChevronRight />
                </button>
            </div>
        </div>
    );
};

export default VisaApplicationGroup;
