import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { apiService } from '../../../services/apiService';
import { useNavigate } from 'react-router-dom';
import TravelAgentActivationModal from './TravelAgentActivationModal'
import './ActivationRequested.css';

const ActivationRequested = () => {
    const [travelAgents, setTravelAgents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
    const [selectedTravelAgentId, setSelectedTravelAgentId] = useState(null);
    const limit = 10;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTravelAgents = async () => {
            try {
                const response = await apiService.get(`/internal/travel-agents/activation-requested?page=${currentPage}&limit=${limit}`);
                setTravelAgents(response.travelAgents);
                setTotalCount(response.totalCount);
            } catch (error) {
                console.error('Error fetching travel agents:', error);
            }
        };

        fetchTravelAgents();
    }, [currentPage, isActivationModalOpen]);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / limit)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleRowClick = (travelAgentId) => {
        setSelectedTravelAgentId(travelAgentId);
        setIsActivationModalOpen(true);
    };


    return (
        <>
            <div className="main-container">
                <h1>Activation Requests</h1>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th>Travel Agent Name</th>
                            <th>Request Date</th>
                            <th>Point of Contact Name</th>
                            <th>City</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            travelAgents.map(agent => (
                                <tr key={agent._id} onClick={() => handleRowClick(agent._id)}>
                                    <td>{agent.businessName}</td>
                                    <td>{new Date(agent.activationSubmittedTime).toLocaleDateString()}</td>
                                    <td>{agent.pointOfContactName}</td>
                                    <td>{agent.city}</td>
                                    <td>{agent.stateId ? agent.stateId.name : 'N/A'}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <div className="pagination-controls">
                    <button
                        className="pagination-button"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        <FaChevronLeft /> Previous
                    </button>
                    <span>Page {currentPage} of {Math.ceil(totalCount / limit)}</span>
                    <button
                        className="pagination-button"
                        onClick={handleNextPage}
                        disabled={currentPage === Math.ceil(totalCount / limit)}
                    >
                        Next <FaChevronRight />
                    </button>
                </div>
            </div>
            {isActivationModalOpen && (
                <TravelAgentActivationModal
                    travelAgentId={selectedTravelAgentId}
                    onClose={() => setIsActivationModalOpen(false)}
                />
            )}
        </>
    );
};

export default ActivationRequested;
