import React from 'react';

const ConfirmationModal = ({ onClose, onConfirm }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Are you sure you want to approve this?</h3>
                <div className="modal-actions">
                    <button onClick={onClose} className="cancel-button">
                        No, go back
                    </button>
                    <button onClick={onConfirm} className="submit-button">
                        Yes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
