import React, { useState, useEffect } from 'react';
import { apiService } from '../../services/apiService';
import { toast } from 'react-hot-toast';

const NewApplicationModal = ({ onClose, onCreate }) => {
    const [countries, setCountries] = useState([]);
    const [visaTypes, setVisaTypes] = useState([]);
    const [travelAgents, setTravelAgents] = useState([]);
    const [applicableVisaTypes, setApplicableVisaTypes] = useState([]);
    const [selectedTravelAgent, setSelectedTravelAgent] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedPurpose, setSelectedPurpose] = useState('');
    const [travelDateFrom, setTravelDateFrom] = useState('');
    const [travelDateTo, setTravelDateTo] = useState('');
    const [selectedVisaType, setSelectedVisaType] = useState('');
    const [numberOfTravellers, setNumberOfTravellers] = useState(1);
    const [groupName, setGroupName] = useState("");
    const [numberOfDays, setNumberOfDays] = useState(1);
    const [notes, setNotes] = useState('');

    const purposes = ['Tourist', 'Business'];


    const fetchCountries = async () => {
        try {
            const response = await apiService.get('/internal/countries');
            setCountries(response.countries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchVisaTypes = async () => {
        try {
            const response = await apiService.get('/internal/visa-types');
            setVisaTypes(response.visaTypes);
        } catch (error) {
            console.error('Error fetching visa types:', error);
        }
    };

    const fetchTravelAgents = async () => {
        try {
            const response = await apiService.get('/internal/travel-agents');
            setTravelAgents(response.travelAgents);
        } catch (error) {
            console.error('Error fetching visa types:', error);
        }
    };

    useEffect(() => {
        fetchCountries();
        fetchVisaTypes();
        fetchTravelAgents();
    }, []);

    useEffect(() => {
        if (travelDateFrom && travelDateTo) {
            const fromDate = new Date(travelDateFrom);
            const toDate = new Date(travelDateTo);
            const differenceInTime = toDate - fromDate;
            setNumberOfDays(differenceInTime / (1000 * 3600 * 24) + 1)
        }
    }, [travelDateFrom, travelDateTo])

    useEffect(() => {
        setApplicableVisaTypes(
            visaTypes
                .filter(visa => visa.countryId._id == selectedCountry)
                .filter(visa => visa.visaPurpose == selectedPurpose)
                .filter(visa => visa.durationOfStay > numberOfDays || visa.maxDurationOfStay > numberOfDays)
        )
    }, [selectedCountry, selectedPurpose, numberOfDays])

    useEffect(() => {
        if (selectedCountry && selectedVisaType && numberOfTravellers)
            setGroupName(`${countries.filter(c => c._id == selectedCountry)[0].name || ""} - ${selectedVisaType.visaName || ""} ${numberOfTravellers ? " x " + numberOfTravellers : ""}`)
    }, [selectedCountry, selectedVisaType, numberOfTravellers])

    const handleVisaTypeClick = (visaType) => {
        setSelectedVisaType(visaType);
    };

    const handleSubmit = async () => {
        try {
            const newApplicationGroup = {
                travelAgentId: selectedTravelAgent,
                travelDateFrom,
                travelDateTo,
                numberOfTravellers,
                visaTypeId: selectedVisaType._id,
                groupName,
                travelAgentNotes: notes,
            };

            const newApplicationGroupPromise = apiService.post('/internal/active-application-groups', newApplicationGroup);

            toast.promise(newApplicationGroupPromise, {
                loading: "Creating application...",
                success: "Application created successfully!",
                failure: "Error creating application"
            });

            newApplicationGroupPromise.then(res => {
                onCreate(res.visaApplicationGroup._id);
                onClose();
            });

        } catch (error) {
            console.error('Error creating visa application group:', error);
        }
    };

    const today = new Date(Date.now() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>New Visa Application</h2>
                <div className="form-group">
                    <label>Travel Agent</label>
                    <select
                        value={selectedTravelAgent}
                        onChange={(e) => setSelectedTravelAgent(e.target.value)}
                        required
                    >
                        <option value="">Select Travel Agent</option>
                        {travelAgents.map((travelAgent) => (
                            <option key={travelAgent._id} value={travelAgent._id}>
                                {travelAgent.businessName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Country</label>
                    <select
                        value={selectedCountry}
                        onChange={(e) => setSelectedCountry(e.target.value)}
                        required
                    >
                        <option value="">Select Country</option>
                        {countries.map((country) => (
                            <option key={country._id} value={country._id}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Purpose</label>
                    <select
                        value={selectedPurpose}
                        onChange={(e) => setSelectedPurpose(e.target.value)}
                        required
                    >
                        <option value="" disabled>Select Purpose</option>
                        {purposes.map((purpose) => (
                            <option key={purpose} value={purpose}>
                                {purpose}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedCountry && selectedPurpose && (
                    <div className="form-group">
                        <label>Travel Dates</label>
                        <input
                            type="date"
                            value={travelDateFrom}
                            min={today}
                            onChange={(e) => setTravelDateFrom(e.target.value)}
                            required
                        />
                        &nbsp;to&nbsp;
                        <input
                            type="date"
                            value={travelDateTo}
                            disabled={!travelDateFrom}
                            min={travelDateFrom}
                            onChange={(e) => setTravelDateTo(e.target.value)}
                            required
                        />
                    </div>
                )}
                {selectedCountry && selectedPurpose && travelDateFrom && travelDateTo && (
                    <div className="visa-type-group">
                        <label>Visa Type</label>
                        <div className="visa-types">
                            {applicableVisaTypes.map((visaType) => (
                                <div
                                    key={visaType._id}
                                    className={`visa-type ${selectedVisaType._id === visaType._id ? 'selected' : ''}`}
                                    onClick={() => handleVisaTypeClick(visaType)}
                                >
                                    {visaType.visaName}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <label>Number of Travellers</label>
                    <input
                        type="number"
                        min="1"
                        value={numberOfTravellers}
                        onChange={(e) => setNumberOfTravellers(e.target.value)}
                        onWheel={(e) => e.target.blur()}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Group Name</label>
                    <input
                        type="text"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        onWheel={(e) => e.target.blur()}
                    />
                </div>
                <div className="form-group">
                    <label>Notes for Visa Centrum Team (optional)</label>
                    <textarea
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    ></textarea>
                </div>
                <div className="modal-actions">
                    <button onClick={onClose}>Cancel</button>
                    <button onClick={handleSubmit}>Start Application</button>
                </div>
            </div>
        </div>
    );
};

export default NewApplicationModal;
