import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { FaRegTrashAlt, FaUpload, FaArrowLeft } from 'react-icons/fa';
import './DocumentPreview.css';
import { toast } from 'react-hot-toast';
import { backendToFrontEndStatusMapping } from '../../config/constants';
import NeedsAttentionModal from './NeedsAttentionModal';
import ApproveModal from './ApproveModal';

const DocumentPreview = () => {
    const { id } = useParams();
    const [documents, setDocuments] = useState([]);
    const [visaApplicationId, setVisaApplicationId] = useState('');
    const [showTooltip, setShowTooltip] = useState(null); // Store the document ID for which the tooltip is shown
    const [statusAndComments, setStatusAndComments] = useState({}); // Store status and comment for each document group
    const [draggingOver, setDraggingOver] = useState(null); // To track if dragging over a specific docGroup
    const [showNeedsAttentionModal, setShowNeedsAttentionModal] = useState(false);
    const [needsAttentionDocGroupId, setNeedsAttentionDocGroupId] = useState(null);
    const [approveDocGroupId, setApproveDocGroupId] = useState(null);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [comment, setComment] = useState('');
    const navigate = useNavigate();

    const handleApproveClick = (docGroupId) => {
        setApproveDocGroupId(docGroupId);
        setShowApproveModal(true);
    };

    const handleApproveConfirm = () => {
        try {
            const data = { status: "Approved", commentText: comment };
            const submitNeedsAttentionPromise = apiService.post(`/internal/visa-application-document/update-status/${approveDocGroupId}`, data);

            toast.promise(submitNeedsAttentionPromise, {
                loading: "Updating document status",
                success: () => {
                    fetchDocuments();
                    return "Document status updated"
                },
                error: "Error updating document status"
            })
        } catch (error) {
            toast.error('Error updating document status');
        }
        setShowApproveModal(false);

    };


    const handleNeedsAttentionClick = (docGroupId) => {
        setNeedsAttentionDocGroupId(docGroupId);
        setShowNeedsAttentionModal(true);
    };

    const handleNeedsAttentionSubmit = () => {
        try {
            const data = { status: "Needs Attention", commentText: comment };
            const submitNeedsAttentionPromise = apiService.post(`/internal/visa-application-document/update-status/${needsAttentionDocGroupId}`, data);

            toast.promise(submitNeedsAttentionPromise, {
                loading: "Updating document status",
                success: () => {
                    fetchDocuments();
                    return "Document status updated"
                },
                error: "Error updating document status"
            })
        } catch (error) {
            toast.error('Error updating document status');
        }
        setShowNeedsAttentionModal(false);
        setComment('');
    };



    const fetchDocuments = async () => {
        try {
            const response = await apiService.get(`/internal/visa-application/${id}?includeSignedUrls=1&includeDocumentComments=1`);
            setDocuments(response.visaApplicationDocuments);
            setVisaApplicationId(response.visaApplication._id);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    useEffect(() => {
        // Fetch the document details from the API
        fetchDocuments();
    }, [id]);

    const toastifyDeleteFailure = () => {
        toast.error('Error Deleting. Try again!');
    }

    const toastifyUploadFailure = () => {
        toast.error('Error Uploading. Try again!');
    };

    const handleDelete = (documentId) => {
        setShowTooltip(null);

        try {
            const deletePromise = apiService.delete(`/internal/visa-application/delete-document/${documentId}`);

            deletePromise.then(async () => {
                fetchDocuments();
            })

            toast.promise(deletePromise, {
                loading: 'Deleting...',
                success: 'Delete successful!',
                error: 'Delete failed! Try again.'
            });
        } catch (error) {
            console.error('Error deleting documents:', error);
            toastifyDeleteFailure();
        }
    }

    const handleTooltip = (docId) => {
        // Toggle the tooltip visibility
        setShowTooltip(showTooltip === docId ? null : docId);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest(".tooltip"))
                return;

            if (showTooltip) {
                setShowTooltip(null); // Hide tooltip when clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);

    const handleDragOver = (e, docGroup) => {
        e.preventDefault();
        setDraggingOver(docGroup._id); // Track which document group is being dragged over
    };

    const handleDrop = async (e, docGroup) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        setDraggingOver(null);

        if (files.length) {
            const formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }

            try {
                // Use the upload method in apiService
                const uploadPromise = apiService.upload(`/internal/upload/visa-application/${docGroup._id}`, formData);

                uploadPromise.then(async () => {
                    // Refresh document checklist after upload
                    fetchDocuments();
                })

                toast.promise(uploadPromise, {
                    loading: 'Uploading...',
                    success: 'Upload successful!',
                    error: 'Upload failed! Try again.'
                });
            } catch (error) {
                console.error('Error uploading documents:', error);
                toastifyUploadFailure();
            }
        }
    };

    const handleFileSelect = async (e, docGroup) => {
        const files = e.target.files;

        if (files.length) {
            const formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }

            try {
                // Use the upload method in apiService
                const uploadPromise = apiService.upload(`/internal/upload/visa-application/${docGroup._id}`, formData);

                uploadPromise.then(async () => {
                    // Refresh document checklist after upload
                    fetchDocuments();
                })

                toast.promise(uploadPromise, {
                    loading: 'Uploading...',
                    success: 'Upload successful!',
                    error: 'Upload failed! Try again.'
                });
            } catch (error) {
                console.error('Error uploading documents:', error);
                toastifyUploadFailure();
            }
        }
    };

    const handleStatusChange = (docGroupId, status) => {
        setStatusAndComments((prev) => ({
            ...prev,
            [docGroupId]: { ...prev[docGroupId], status },
        }));
    };

    const handleCommentChange = (docGroupId, commentText) => {
        setStatusAndComments((prev) => ({
            ...prev,
            [docGroupId]: { ...prev[docGroupId], commentText },
        }));
    };

    const handleSubmit = async (docGroupId) => {
        const { status, commentText } = statusAndComments[docGroupId] || {};
        if (status === 'Needs Attention' && !commentText) {
            toast.error('Comment is required for "Needs Attention" status.');
            return;
        }

        try {
            const data = { status, commentText };
            await apiService.post(`/internal/visa-application-document/update-status/${docGroupId}`, data);
            toast.success('Status updated successfully!');
            handleStatusChange(docGroupId, "");
            handleCommentChange(docGroupId, "");
            fetchDocuments();
        } catch (error) {
            toast.error('Failed to update status.');
            console.error('Error updating status:', error);
        }
    };

    const renderDocument = (doc, docGroup) => {
        return (
            <div className="document-item-preview">
                {doc.mimeType.includes('pdf') ?
                    <>
                        <div className="document-pdf">
                            <iframe src={doc.preSignedUrl}></iframe>
                        </div>
                        {docGroup.reviewStatus !== 'Approved' && <div className="delete-icon-div">
                            <FaRegTrashAlt className="delete-icon" onClick={() => handleTooltip(doc._id)} />
                        </div>}
                        {showTooltip === doc._id && (
                            <div className="tooltip">
                                <p>Are you sure you want to delete this document?</p>
                                <div className="tooltip-buttons">
                                    <button className="btn-yes" onClick={() => handleDelete(doc._id)}>Yes</button>
                                    <button className="btn-no" onClick={() => setShowTooltip(null)}>No</button>
                                </div>
                            </div>
                        )}
                    </>
                    : doc.mimeType.includes('image') ?
                        <>
                            <img src={doc.preSignedUrl} className="document-image" />
                            {docGroup.reviewStatus !== 'Approved' && <div className="delete-icon-div">
                                <FaRegTrashAlt className="delete-icon" onClick={() => handleTooltip(doc._id)} />
                            </div>}
                            {showTooltip === doc._id && (
                                <div className="tooltip">
                                    <p>Are you sure you want to delete this document?</p>
                                    <div className="tooltip-buttons">
                                        <button className="btn-yes" onClick={() => handleDelete(doc._id)}>Yes</button>
                                        <button className="btn-no" onClick={() => setShowTooltip(null)}>No</button>
                                    </div>
                                </div>
                            )}
                        </>
                        :
                        <FaUpload className="upload-icon" />
                }

            </div>)
    };

    return (
        <div className="main-container">
            {/* <span className="back-button" onClick={() => navigate(`/visa-application/${visaApplicationId}`)}>
                <FaArrowLeft /> Back
            </span> */}
            {/* <div className="document-header">
                <h2>Document Preview</h2>
            </div> */}
            <div className="document-list">
                {documents.map((docGroup, index) => (
                    <div
                        key={index}
                        className={`document-group ${draggingOver === docGroup._id ? 'drag-over' : ''}`}
                        onDragOver={(e) => handleDragOver(e, docGroup)}
                        onDrop={(e) => handleDrop(e, docGroup)}
                        id={docGroup._id}
                    >
                        <div className="visa-application-document-header">
                            <div className="visa-application-document-title">{docGroup.documentChecklistItemId.documentTitle} [{docGroup.reviewStatus}]</div>
                            <div className="visa-application-document-review-actions">
                                <span onClick={() => handleNeedsAttentionClick(docGroup._id)} className="visa-application-document-review-action needs-attention">Needs Attention</span>
                                <span onClick={() => handleApproveClick(docGroup._id)} className="visa-application-document-review-action approve">Approve</span>
                            </div>
                        </div>

                        {docGroup.latestComment && docGroup.reviewStatus == 'Needs Attention' && (
                            <div className="comment-section">
                                <p><strong>Comment:</strong> {docGroup.latestComment.commentText}</p>
                                <p><strong>Timestamp:</strong> {new Date().toLocaleString()}</p>
                            </div>
                        )}

                        <div className="status-control">
                            <label>Update Status: </label>
                            <select
                                value={statusAndComments[docGroup._id]?.status || ''}
                                onChange={(e) => handleStatusChange(docGroup._id, e.target.value)}
                            >
                                <option value="">Select Status</option>
                                <option value="Approved">Approved</option>
                                <option value="Needs Attention">Needs Attention</option>
                            </select>
                        </div>

                        {statusAndComments[docGroup._id]?.status === 'Needs Attention' && (
                            <div className="comment-control">
                                <textarea
                                    placeholder="Enter comment"
                                    value={statusAndComments[docGroup._id]?.commentText || ''}
                                    onChange={(e) => handleCommentChange(docGroup._id, e.target.value)}
                                    required
                                />
                            </div>
                        )}

                        <button
                            className={statusAndComments[docGroup._id]?.status ? "doc-status-submit-button" : "doc-status-submit-button-disabled"}
                            onClick={() => handleSubmit(docGroup._id)}
                            disabled={!statusAndComments[docGroup._id]?.status}
                        >
                            Submit
                        </button>

                        <div className="doc-preview-upload-pill">
                            <FaUpload className="doc-preview-upload-icon" />
                            <span>Upload</span>
                            <input
                                type="file"
                                multiple
                                accept=".pdf,.png,.jpg,.jpeg,.gif"
                                onChange={(e) => handleFileSelect(e, docGroup)}
                                className="file-input"
                            />
                        </div>
                        {docGroup.uploadedDocumentIds.length ? docGroup.uploadedDocumentIds.map((doc, docIndex) => (
                            <div key={docIndex}>
                                {renderDocument(doc, docGroup)}
                            </div>
                        )) :
                            <div className='document-item-preview'>
                                <FaUpload className="upload-icon" />
                            </div>
                        }
                    </div>
                ))}
            </div>
            {showNeedsAttentionModal && (
                <NeedsAttentionModal
                    onClose={() => setShowNeedsAttentionModal(false)}
                    onSubmit={handleNeedsAttentionSubmit}
                    comment={comment}
                    setComment={setComment}
                />
            )}
            {showApproveModal && (
                <ApproveModal
                    onClose={() => setShowApproveModal(false)}
                    onConfirm={handleApproveConfirm}
                />
            )}
        </div>
    );
};

export default DocumentPreview;
