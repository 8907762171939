import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import ActiveApplications from './components/ActiveApplications';
import VisaApplicationGroup from './components/VisaApplicationGroup';
import VisaApplication from './components/VisaApplication';
import DocumentPreview from './components/DocumentPreview';
// import TravelAgents from './components/TravelAgents';
import ActivationRequested from './components/TravelAgents/ActivationRequested';
import Travellers from './components/Travellers';
import Reports from './components/Reports';
import Countries from './components/MasterData/Countries';
import States from './components/MasterData/States';
import Purposes from './components/MasterData/Purposes';
import VisaTypes from './components/MasterData/VisaTypes';
import DocumentChecklists from './components/MasterData/DocumentChecklists';
import DocumentChecklistPage from './components/MasterData/DocumentChecklists/DocumentChecklistPage';
import Consulates from './components/MasterData/Consulates';
import Workflows from './components/MasterData/Workflows';
import UserManagement from './components/UserManagement';
import ManualTransactions from './components/Finance/ManualTransactions';
import Login from './pages/Login';
import { UserProvider } from './context/UserContext';
import { Toaster } from 'react-hot-toast';
import './App.css'


const AppContent = () => {
  const location = useLocation();

  return (
    <div className="app">
      {location.pathname !== '/login' && <Sidebar />}
      <div className={`${location.pathname === '/login' ? 'full-width' : 'main-content'}`}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/visa-applications" element={<ActiveApplications />} />
          <Route path="/visa-application-group/:id" element={<VisaApplicationGroup />} />
          <Route path="/visa-application/:id" element={<VisaApplication />} />
          <Route path="/visa-application/:id/documents" element={<DocumentPreview />} />
          <Route path="/travel-agents/approved-agents" element={<ActivationRequested />} />
          <Route path="/travel-agents/activation-requested" element={<ActivationRequested />} />
          <Route path="/travel-agents/unapproved-agents" element={<ActivationRequested />} />
          <Route path="/travellers" element={<Travellers />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/master-data/countries" element={<Countries />} />
          <Route path="/master-data/states" element={<States />} />
          <Route path="/master-data/purposes" element={<Purposes />} />
          <Route path="/master-data/visa-types" element={<VisaTypes />} />
          <Route path="/master-data/document-checklists" element={<DocumentChecklists />} />
          <Route path="/master-data/document-checklist/:visaTypeId" element={<DocumentChecklistPage />} />
          <Route path="/master-data/consulates" element={<Consulates />} />
          <Route path="/master-data/workflows" element={<Workflows />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/finance/manual-transactions" element={<ManualTransactions />} />
        </Routes>
      </div>
      <Toaster />
    </div>
  );
}


const App = () => (
  <Router>
    <UserProvider>
      <AppContent />
    </UserProvider>
  </Router>
);

export default App;
