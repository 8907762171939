// src/components/MasterData/CreateStateModal.js

import React, { useState, useEffect } from 'react';

const CreateStateModal = ({ onClose, onCreate, onEdit, isEditing, initialData }) => {
    const [name, setName] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        if (isEditing && initialData) {
            setName(initialData.name);
            setCode(initialData.code);
        }
    }, [isEditing, initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const stateData = { name, code };
        if (isEditing) {
            onEdit(stateData);
        } else {
            onCreate(stateData);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{isEditing ? 'Edit State' : 'Create New State'}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>State Name</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>State Code</label>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                    </div>
                    <div className="modal-actions">
                        <button type="button" onClick={onClose} className="cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="create-button">
                            {isEditing ? 'Save Changes' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateStateModal;
