import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { FaUpload, FaUserCircle, FaPlaneDeparture, FaGlobeAfrica, FaPassport, FaFileAlt, FaArrowLeft, FaRegEdit, FaChevronDown } from 'react-icons/fa';
import { MdOutlineCancel, MdOutlineCheckCircle } from "react-icons/md";
import './VisaApplication.css';
import { backendToFrontEndStatusMapping } from '../../config/constants';
import { toast } from 'react-hot-toast';
import VisaSubmissionModal from './VisaSubmissionModal';
import VisaDecisionModal from './VisaDecisionModal';
import DocumentPreview from '../DocumentPreview';

const VisaApplication = () => {
    const { id } = useParams();
    const [visaApplication, setVisaApplication] = useState(null);
    const [visaApplicationGroup, setVisaApplicationGroup] = useState(null);
    const [documentChecklist, setDocumentChecklist] = useState([]);
    const [travelDateFrom, setTravelDateFrom] = useState(new Date());
    const [applicationCost, setApplicationCost] = useState(0); // State for total cost
    const [walletBalance, setWalletBalance] = useState(0); // State for wallet balance
    const [editingTraveller, setEditingTraveller] = useState(false); // State for wallet balance
    const [travellerName, setTravellerName] = useState(false);
    const [showInvoicesDropdown, setShowInvoicesDropdown] = useState(false);
    const [invoicesForVisaType, setInvoicesForVisaType] = useState([]);
    const [invoicesGenerated, setInvoicesGenerated] = useState([]);
    const [invoicesPending, setInvoicesPending] = useState([]);
    const [showSubmissionDropdown, setShowSubmissionDropdown] = useState(false);
    const [visaWorkflow, setVisaWorkflow] = useState(null);
    const [eligibleSubmissions, setEligibleSubmissions] = useState([]);
    const [submissionsDone, setSubmissionsDone] = useState([]);
    const [submissionsPendingDecision, setSubmissionsPendingDecision] = useState([]);
    const [showSubmissionModal, setShowSubmissionModal] = useState(false);
    const submissionDropdownRef = useRef(null);
    const [selectedSubmissionName, setSelectedSubmissionName] = useState(null);
    const [travelAgent, setTravelAgent] = useState(null);
    const [showDecisionDropdown, setShowDecisionDropdown] = useState(false);
    const [showDecisionModal, setShowDecisionModal] = useState(false);
    const [selectedSubmissionForDecision, setSelectedSubmissionForDecision] = useState(false);
    const [visasAvailableForDownload, setVisasAvailableForDownload] = useState([]);
    const decisionDropdownRef = useRef(null);
    const dropdownRef = useRef(null);
    const fileInputRefs = useRef({}); // To reference each file input
    const [dragOver, setDragOver] = useState(null); // Track the document item being dragged over
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const navigate = useNavigate();

    const toastifyUploadFailure = () => {
        toast.error('Error Uploading. Try again!');
    };

    const fetchVisaApplicationDetails = async () => {
        try {
            const applicationData = await apiService.get(`/internal/visa-application/${id}`);
            setVisaApplication(applicationData.visaApplication);
            setDocumentChecklist(applicationData.visaApplicationDocuments);
            setVisaApplicationGroup(applicationData.visaApplicationGroup);
            setTravelDateFrom(new Date(applicationData.visaApplicationGroup.travelDateFrom));
            setTravellerName(applicationData.visaApplication.travellerName || "");
            // Fetch total cost of visa application group
            const applicationCostData = await apiService.get(`/internal/invoice/calculate-invoice/visa-application/${id}`);
            setApplicationCost(applicationCostData.map(invoice => invoice.totalAmount).reduce((prevValue, currentValue) => prevValue * currentValue, 1));

            setInvoicesForVisaType(applicationCostData);
            setInvoicesGenerated(applicationData.visaApplication.invoices);
            setInvoicesPending(applicationCostData.filter(invoice => !applicationData.visaApplication.invoices.map(generatedInvoice => generatedInvoice.type).includes(invoice.invoiceType)));

            const travelAgentData = await apiService.get(`/internal/travel-agents/${applicationData.visaApplicationGroup.travelAgentId}`);
            setTravelAgent(travelAgentData.travelAgent);
            setWalletBalance(travelAgentData.travelAgent.wallet.balance);

            const workflowJson = applicationData.visaApplicationGroup?.visaTypeId?.visaWorkflowId?.workflowJson;

            setVisaWorkflow(applicationData.visaApplicationGroup?.visaTypeId?.visaWorkflowId?.workflowJson);

            const invoicesGenerated = applicationData.visaApplication.invoices.map(invoice => invoice.type);
            const eventsGenerated = applicationData.visaApplication.lifecycleEvents;

            setEligibleSubmissions(Object.keys(workflowJson.submissions).map(key => {
                return { key, ...workflowJson.submissions[key] }
            })
                .filter(submission =>
                    invoicesGenerated.includes(submission.dependentOnInvoice)
                    && submission.dependentOnEvents.every(event => eventsGenerated.includes(event))
                    && !eventsGenerated.includes(submission.event)
                ));

            setSubmissionsDone(applicationData.visaApplication.submissions);
            setSubmissionsPendingDecision(applicationData.visaApplication.submissions.filter(submission => !submission.submissionId.visaDecision));

            setVisasAvailableForDownload(applicationData.visaApplication.submissions.filter(submission => submission.submissionId.visaDecision).filter(submission => submission.submissionId.visaDecision.visaCopyfileKey));

        } catch (error) {
            console.error('Error fetching visa application details:', error);
        }
    };

    useEffect(() => {
        fetchVisaApplicationDetails();
    }, [id]);

    const handleGenerateInvoice = (invoiceType) => {
        try {
            const generateInvoicePromise = apiService.post(`/internal/invoice/generate-invoice/visa-application/${visaApplication._id}`, { visaApplicationId: visaApplication._id, invoiceType });

            toast.promise(generateInvoicePromise, {
                loading: "Generating invoice...",
                success: "Invoice generated successfully!",
                failure: "Error generating invoice"
            })

            generateInvoicePromise.then(res => {
                fetchVisaApplicationDetails();
            });

        } catch (error) {
            console.error('Error generating invoice:', error);
            alert('Failed to generate invoice.');
        }
    }

    // Handle file selection and upload
    const handleFileSelect = async (files, document) => {
        if (files && files.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }
            try {
                // Use the upload method in apiService
                const uploadPromise = apiService.upload(`/internal/upload/visa-application/${document._id}`, formData);

                uploadPromise.then(async () => {
                    // Refresh document checklist after upload
                    fetchVisaApplicationDetails();
                })

                toast.promise(uploadPromise, {
                    loading: 'Uploading...',
                    success: 'Upload successful!',
                    error: 'Upload failed! Try again.'
                });
            } catch (error) {
                console.error('Error uploading documents:', error);
                toastifyUploadFailure();
            }
        }
    };

    // Drag and drop handlers
    const handleDragOver = (e, document) => {
        e.preventDefault();
        setDragOver(document._id); // Mark the item being dragged over
    };

    const handleDragLeave = () => {
        setDragOver(null); // Reset when the drag leaves the item
    };

    const handleDrop = (e, document) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFileSelect(files, document);
        setDragOver(null); // Reset drag-over state
    };

    const documentContainerClicked = (document) => {
        navigate(`/visa-application/${visaApplication._id}/documents#${document._id}`);
    }

    // Render document upload divs or thumbnails
    const renderDocumentItem = (document) => {
        const inputId = `file-input-${document._id}`; // Unique id for each document's input

        return (
            <div
                className={`document-item ${dragOver === document._id ? 'document-item--dragover' : ''}`}
                onClick={() => documentContainerClicked(document)}
                onDragOver={(e) => handleDragOver(e, document)}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, document)}
            >
                {document.uploadedDocumentIds.length === 0 ? (
                    <FaUpload className="upload-icon" />
                ) : (
                    <FaFileAlt className="upload-icon" />
                )}
                <p>{document.documentChecklistItemId.documentTitle}</p>
                <p>
                    <span className={document.uploadedDocumentIds.length === 0 ? 'status-pending' : 'status-uploaded'}>
                        {document.reviewStatus}
                    </span>
                </p>
                {/* Hidden input for file upload */}
                <input
                    type="file"
                    id={inputId}
                    ref={(el) => (fileInputRefs.current[document._id] = el)}
                    style={{ display: 'none' }}
                    multiple
                    accept=".pdf,.png,.jpg,.jpeg,.gif"
                    onChange={(event) => handleFileSelect(event.target.files, document)}
                />
            </div>
        );
    };

    const handleTravellerEditClick = () => {
        setEditingTraveller(true);
    }

    const handleTravellerNameInputChange = (e) => {
        setTravellerName(e.target.value);
    }

    const handleTravellerInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleTravellerNameSave();
        } else if (e.key === 'Escape') {
            setEditingTraveller(false);
        }
    }

    const handleTravellerEditCancel = () => {
        setEditingTraveller(false);
    }

    const handleTravellerNameSave = () => {
        setEditingTraveller(false);
        if (!travellerName) {
            toast.error("Enter traveller name");
            return;
        }

        const updateTravellerPromise = apiService.put(`/internal/visa-application/update-traveller/${visaApplication._id}`, { travellerName });

        toast.promise(updateTravellerPromise, {
            loading: "Updating traveller name...",
            success: "Traveller name updated",
            error: "Error updating traveller name"
        });

        updateTravellerPromise.then((res) => {
            setVisaApplicationGroup(res.visaApplicationGroup);
            setVisaApplication(res.visaApplication);
        }).catch((err) => {
            toast.error("Error updating traveller name");
        })
    }

    const toggleDropdown = () => {
        setShowInvoicesDropdown(!showInvoicesDropdown); // Toggle dropdown visibility
    };

    const toggleSubmissionDropdown = () => {
        setShowSubmissionDropdown(!showSubmissionDropdown); // Toggle dropdown visibility
    };

    const toggleDecisionDropdown = () => {
        setShowDecisionDropdown(!showDecisionDropdown); // Toggle dropdown visibility
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowInvoicesDropdown(false);
            }
        };

        // Attach event listener to detect clicks outside of the dropdown
        if (showInvoicesDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener when the component unmounts or when the dropdown is closed
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showInvoicesDropdown]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (submissionDropdownRef.current && !submissionDropdownRef.current.contains(event.target)) {
                setShowSubmissionDropdown(false);
            }
        };

        // Attach event listener to detect clicks outside of the dropdown
        if (showSubmissionDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener when the component unmounts or when the dropdown is closed
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSubmissionDropdown]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (decisionDropdownRef.current && !decisionDropdownRef.current.contains(event.target)) {
                setShowDecisionDropdown(false);
            }
        };

        // Attach event listener to detect clicks outside of the dropdown
        if (showDecisionDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener when the component unmounts or when the dropdown is closed
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDecisionDropdown]);

    const handleDownloadInvoice = async () => {

        try {
            const response = await apiService.get(`/internal/invoice/generate-pdf-invoice/visa-application-id/${visaApplication._id}`, {
                responseType: 'blob'
            });

            const blob = new Blob([response], { type: 'application/pdf' });

            // Create a URL for the Blob
            const fileURL = URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(fileURL, '_blank');
        } catch (error) {
            toast.error('Error downloading the invoice');
            console.error('Error downloading invoice:', error);
        }
    }

    const handleDownloadSubmissionReceipt = async () => {

        try {
            const response = await apiService.get(`/internal/visa-application/get-submission-receipts/${visaApplication._id}`, {
                responseType: 'blob'
            });

            const blob = new Blob([response], { type: 'application/pdf' });

            // Create a URL for the Blob
            const fileURL = URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(fileURL, '_blank');
        } catch (error) {
            toast.error('Error downloading the invoice');
            console.error('Error downloading invoice:', error);
        }
    }

    const handleDownloadVisaCopy = async () => {
        try {
            const response = await apiService.get(`/internal/visa-application/get-visa-copy/${visaApplication._id}`, {
                responseType: 'blob'
            });

            const blob = new Blob([response], { type: 'application/pdf' });

            // Create a URL for the Blob
            const fileURL = URL.createObjectURL(blob);

            // Open the URL in a new tab
            window.open(fileURL, '_blank');
        } catch (error) {
            toast.error('Error downloading the invoice');
            console.error('Error downloading invoice:', error);
        }
    }

    const onSubmissionUpdated = () => {
        fetchVisaApplicationDetails();
    }

    const submissionNameClicked = (submissionName) => {
        setSelectedSubmissionName(submissionName);
        setShowSubmissionModal(true);
    }

    const onDecisionUpdated = () => {
        fetchVisaApplicationDetails();
        setShowDecisionModal(false);
    }

    const recordDecision = (submissionId) => {
        console.log("submissionId", submissionId)
        setSelectedSubmissionForDecision(submissionId);
        setShowDecisionModal(true);
    }

    if (!visaApplication) {
        return <div>Loading...</div>;
    }

    return (
        <div className="main-container">
            <span className="back-button" onClick={() => navigate(`/visa-application-group/${visaApplicationGroup._id}`)}>
                <FaArrowLeft /> Back
            </span>
            <div className="visa-application-header">
                <div className="visa-application-header-top-pane">
                    <h2>Details</h2>
                    <div className="visa-application-status-div">
                        <label>Status</label>
                        <div className="visa-application-status-indicator status-color-customer-pending">
                            {backendToFrontEndStatusMapping[visaApplication.internalStatus]}
                        </div>
                    </div>
                </div>
                <div className="visa-application-header-details">
                    <div className="visa-application-details">
                        <div className="visa-application-details__item">
                            <label>Travel Agent</label>
                            <span className="visa-application-details__item-value">{travelAgent?.businessName}</span>
                        </div>
                        <div className="visa-application-details__item">
                            <label>Visa Type</label>
                            <span className="visa-application-details__item-value">{visaApplicationGroup.visaTypeId.visaName}</span>
                        </div>
                        <div className="visa-application-details__item">
                            <label>Group Name</label>
                            <span className="visa-application-details__item-value">{visaApplicationGroup.groupName}</span>
                        </div>
                        <div className="visa-application-details__item">
                            <label>Country</label>
                            <span className="visa-application-details__item-value">{visaApplicationGroup.visaTypeId.countryId.name}</span>
                        </div>
                        <div className="visa-application-details__item">
                            <label>Travel Date</label>
                            <span className="visa-application-details__item-value">{new Date(visaApplicationGroup.travelDateFrom).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, '-')}</span>
                        </div>

                    </div>
                    <div className="visa-application-cost-details">
                        <div className="visa-application-details__item">
                            <label>Application Cost</label>
                            <span className="visa-application-details__item-value">₹{applicationCost.toLocaleString()}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="visa-application-actions">
                {invoicesGenerated.length ? <div>
                    <span className='visa-application-download-button' onClick={handleDownloadInvoice}>Download Invoice</span>
                </div> : ''}

                {submissionsDone.length ? <div>
                    <span className='visa-application-download-button' onClick={handleDownloadSubmissionReceipt}>Download Submission Receipt</span>
                </div> : ''}

                {visasAvailableForDownload.length ? <div>
                    <span className='visa-application-download-button' onClick={handleDownloadVisaCopy}>Download Visa Copy</span>
                </div> : ''}
                {invoicesPending.length ? <div ref={dropdownRef}>
                    <div className="action-dropdown">
                        <button className="action-dropdown-button" onClick={toggleDropdown}>
                            Pending Invoices <FaChevronDown />
                        </button>
                        {showInvoicesDropdown && (
                            <ul className="action-dropdown-menu">
                                {invoicesPending.map((invoice) => (
                                    <li key={invoice.invoiceType} onClick={travelAgent.approved && invoice.totalAmount < walletBalance ? () => handleGenerateInvoice(invoice.invoiceType) : () => { return }}>
                                        {!travelAgent.approved ? invoice.invoiceName + ' (Travel Agent Not Approved)' : invoice.totalAmount < walletBalance ? invoice.invoiceName : invoice.invoiceName + ' (Insufficient Balance)'}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div> : ''}
                {eligibleSubmissions.length ? <div ref={submissionDropdownRef}>
                    <div className="action-dropdown">
                        <button className="action-dropdown-button" onClick={toggleSubmissionDropdown}>
                            Mark Submitted <FaChevronDown />
                        </button>
                        {showSubmissionDropdown && (
                            <ul className="action-dropdown-menu">
                                {eligibleSubmissions.map((submission) => (
                                    <li key={submission.key} onClick={() => submissionNameClicked(submission.key)}>
                                        {submission.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div> : ''}
                {submissionsPendingDecision.length ? <div ref={decisionDropdownRef}>
                    <div className="action-dropdown">
                        <button className="action-dropdown-button" onClick={toggleDecisionDropdown}>
                            Decision Recieved <FaChevronDown />
                        </button>
                        {showDecisionDropdown && (
                            <ul className="action-dropdown-menu">
                                {submissionsPendingDecision.map((submission) => (
                                    <li key={submission.type} onClick={() => recordDecision(submission.submissionId._id)}>
                                        {visaWorkflow.submissions[submission.type].name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div> : ''}
            </div>
            <DocumentPreview />
            {showSubmissionModal && <VisaSubmissionModal
                onClose={() => setShowSubmissionModal(false)}
                onSubmitSuccess={onSubmissionUpdated}
                visaApplicationId={visaApplication._id}
                submissionName={selectedSubmissionName}
            />}
            {showDecisionModal && <VisaDecisionModal
                onClose={() => setShowDecisionModal(false)}
                onDecisionSubmit={onDecisionUpdated}
                visaApplicationId={visaApplication._id}
                submissionId={selectedSubmissionForDecision}
                visaType={visaApplicationGroup.visaTypeId}
            />

            }
        </div>
    );
};

export default VisaApplication;
