// src/components/Finance/ManualTransactions.js

import React, { useState, useEffect, useContext } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Add FaTimesCircle for reject icon
import { apiService } from '../../../services/apiService';
import './ManualTransactions.css';
import TransactionApprovalModal from './TransactionApprovalModal'; // Import the approval modal
import TransactionRejectionModal from './TransactionRejectionModal'; // Import the rejection modal
import { UserContext } from '../../../context/UserContext'; // Import UserContext
import { debounce } from 'lodash'; // Import lodash for debounce functionality

const ManualTransactions = () => {
    const { user: currentUser } = useContext(UserContext); // Get current user from context
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState(['pending']); // Default filter is 'Pending'
    const [searchText, setSearchText] = useState(''); // State to store search text
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showRejectionModal, setShowRejectionModal] = useState(false); // State to show rejection modal
    const [selectedTransaction, setSelectedTransaction] = useState(null); // Store selected transaction for approval/rejection
    const [currentPage, setCurrentPage] = useState(1); // State to track the current page
    const [totalPages, setTotalPages] = useState(1); // State to track total pages
    const transactionsPerPage = 10; // Set the default transactions count per page to 10

    const fetchTransactions = async () => {
        const pending = selectedFilters.includes('pending');
        const approved = selectedFilters.includes('approved');
        const rejected = selectedFilters.includes('rejected');

        try {
            const response = await apiService.get(
                `/internal/finance/manual-transactions?pending=${pending}&approved=${approved}&rejected=${rejected}&search=${searchText}&page=${currentPage}&limit=${transactionsPerPage}`
            );
            setTransactions(response.transactions);
            setTotalPages(response.totalPages); // Assuming the API returns totalPages
            setLoading(false);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, [selectedFilters, currentPage, searchText]); // Fetch transactions when filters, page, or search text changes

    // Handle search input with debounce to avoid too many API calls
    const handleSearchChange = debounce((value) => {
        setSearchText(value);
        setCurrentPage(1); // Reset to the first page when a new search is initiated
    }, 300); // 300ms debounce delay

    const openApprovalModal = (transaction) => {
        setSelectedTransaction(transaction);
        setShowApprovalModal(true);
    };

    const closeApprovalModal = () => {
        setShowApprovalModal(false);
        setSelectedTransaction(null);
    };

    const openRejectionModal = (transaction) => {
        setSelectedTransaction(transaction);
        setShowRejectionModal(true);
    };

    const closeRejectionModal = () => {
        setShowRejectionModal(false);
        setSelectedTransaction(null);
    };

    const handleApproveTransaction = () => {
        // Refetch transactions after approval
        fetchTransactions(selectedFilters);
        closeApprovalModal();
    };

    const handleRejectTransaction = () => {
        // Refetch transactions after rejection
        fetchTransactions(selectedFilters);
        closeRejectionModal();
    };

    const updateFilters = (filter) => {
        if (selectedFilters.includes(filter)) {
            const newFilters = selectedFilters.filter((f) => f !== filter);
            setSelectedFilters(newFilters);
        } else {
            setSelectedFilters([...selectedFilters, filter]);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    return (
        <div className="main-container">
            <h1>Manual Transactions</h1>
            <div className="filter-search-container">
                <div className="filter-buttons">
                    <button
                        className={selectedFilters.includes('pending') ? 'active' : ''}
                        onClick={() => updateFilters('pending')}
                    >
                        Pending
                    </button>
                    <button
                        className={selectedFilters.includes('approved') ? 'active' : ''}
                        onClick={() => updateFilters('approved')}
                    >
                        Approved
                    </button>
                    <button
                        className={selectedFilters.includes('rejected') ? 'active' : ''}
                        onClick={() => updateFilters('rejected')}
                    >
                        Rejected
                    </button>
                </div>
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Search transactions..."
                        onChange={(e) => handleSearchChange(e.target.value)}
                    />
                </div>
            </div>

            {loading ? (
                <div>Loading transactions...</div>
            ) : (
                <>
                    <table className="transaction-table">
                        <thead>
                            <tr>
                                <th>Travel Agent</th>
                                <th>Transaction Date</th>
                                <th>Reference Number</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction) => (
                                <tr key={transaction._id}>
                                    <td>{transaction.travelAgent.businessName}</td>
                                    <td>{new Date(transaction.transactionDate).toLocaleDateString()}</td>
                                    <td>{transaction.transactionRefNum}</td>
                                    <td>{transaction.amount}</td>
                                    <td>{transaction.status}</td>
                                    <td>
                                        {transaction.status === 'PENDING_APPROVAL' && (
                                            <>
                                                <FaCheckCircle
                                                    className={`approve-icon ${transaction.manualEntryByInternalUserId === currentUser.user._id ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if (transaction.manualEntryByInternalUserId !== currentUser.user._id) {
                                                            openApprovalModal(transaction);
                                                        }
                                                    }}
                                                />
                                                <FaTimesCircle
                                                    className='reject-icon'
                                                    onClick={() => openRejectionModal(transaction)}
                                                />
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination controls */}
                    <div className="pagination-controls">
                        <button
                            className="pagination-button"
                            onClick={goToPreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            className="pagination-button"
                            onClick={goToNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </>
            )}

            {/* Show approval modal if a transaction is selected */}
            {showApprovalModal && selectedTransaction && (
                <TransactionApprovalModal
                    transaction={selectedTransaction}
                    onClose={closeApprovalModal}
                    onApprove={handleApproveTransaction}
                />
            )}

            {/* Show rejection modal if a transaction is selected */}
            {showRejectionModal && selectedTransaction && (
                <TransactionRejectionModal
                    transaction={selectedTransaction}
                    onClose={closeRejectionModal}
                    onReject={handleRejectTransaction}
                />
            )}
        </div>
    );
};

export default ManualTransactions;
