// src/pages/DocumentChecklistPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiService } from '../../../services/apiService';
import { FaTrash, FaPlus, FaArrowLeft } from 'react-icons/fa';
import './DocumentChecklistPage.css';

const DocumentChecklistPage = () => {
    const { visaTypeId } = useParams();
    const [visaTypeName, setVisaTypeName] = useState('');
    const [documentChecklistItems, setDocumentChecklistItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDocumentChecklistItems();
        fetchVisaType();
    }, [visaTypeId]);

    const fetchVisaType = async () => {
        try {
            const response = await apiService.get(`/internal/visa-types/${visaTypeId}`);
            setVisaTypeName(response.visaName);
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchDocumentChecklistItems = async () => {
        try {
            const response = await apiService.get(`/internal/document-checklist/${visaTypeId}`);
            setDocumentChecklistItems(response.documentChecklist);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const handleInputChange = (index, field, value) => {
        const newItems = [...documentChecklistItems];
        newItems[index][field] = value;
        setDocumentChecklistItems(newItems);
    };

    const handleAddRow = () => {
        setDocumentChecklistItems([
            ...documentChecklistItems,
            {
                visaTypeId,
                documentType: 'Passport',
                documentTitle: '',
                documentDescription: '',
                documentMandatory: true,
                originalRequired: 'Required',
                orderingPriority: documentChecklistItems.length
            }
        ]);
    };

    const handleDeleteRow = (index) => {
        const newItems = [...documentChecklistItems];
        newItems[index].delete = true;
        setDocumentChecklistItems(newItems);
    };

    const handleSave = async () => {
        try {
            const response = await apiService.put(`/internal/document-checklist/${visaTypeId}`, documentChecklistItems);
            alert("Document Checklist Saved!")
            setDocumentChecklistItems(response.documentChecklist);
        } catch (error) {
            setError(error.message);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="document-checklist-page">
            <span className="back-button" onClick={() => navigate('/master-data/document-checklists')}>
                <FaArrowLeft /> Back
            </span>
            <h1>Document Checklist: {visaTypeName} </h1>
            <table className="document-checklist-table">
                <thead>
                    <tr>
                        <th>Document Type</th>
                        <th>Document Title</th>
                        <th>Document Description</th>
                        <th>Mandatory</th>
                        <th>Original Required</th>
                        <th>Priority</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {documentChecklistItems.filter(item => !item.delete).map((item, index) => (
                        <tr key={index}>
                            <td>
                                <select
                                    value={item.documentType}
                                    onChange={(e) => handleInputChange(index, 'documentType', e.target.value)}
                                >
                                    <option value="Passport">Passport</option>
                                    <option value="Photo">Photo</option>
                                    <option value="Financial Documents">Financial Documents</option>
                                    <option value="Employment Documents">Employment Documents</option>
                                    <option value="Travel Documents">Travel Documents</option>
                                    <option value="Letters">Letters</option>
                                    <option value="Miscellaneous">Miscellaneous</option>
                                </select>
                            </td>
                            <td>
                                <textarea
                                    value={item.documentTitle}
                                    onChange={(e) => handleInputChange(index, 'documentTitle', e.target.value)}
                                />
                            </td>
                            <td>
                                <textarea
                                    value={item.documentDescription}
                                    onChange={(e) => handleInputChange(index, 'documentDescription', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={item.documentMandatory}
                                    onChange={(e) => handleInputChange(index, 'documentMandatory', e.target.checked)}
                                />
                            </td>
                            <td>
                                <select
                                    value={item.originalRequired}
                                    onChange={(e) => handleInputChange(index, 'originalRequired', e.target.value)}
                                >
                                    <option value="Required">Required</option>
                                    <option value="Preferred">Preferred</option>
                                    <option value="Not Required">Not Required</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={item.orderingPriority}
                                    onChange={(e) => handleInputChange(index, 'orderingPriority', e.target.value)}
                                />
                            </td>
                            <td>
                                <FaTrash className="delete-icon" onClick={() => handleDeleteRow(index)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button className="add-button" onClick={handleAddRow}>
                <FaPlus /> Add Document
            </button>
            <button className={`save-button ${!documentChecklistItems.length ? " disabled" : ""}`} disabled={!documentChecklistItems.length} onClick={handleSave}>
                Save Checklist
            </button>
        </div>
    );
};

export default DocumentChecklistPage;
