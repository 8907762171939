import React from 'react';

const NeedsAttentionModal = ({ onClose, onSubmit, comment, setComment }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Needs Attention</h3>
                <textarea
                    className="modal-textarea"

                    rows={8}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Describe the issue... E.g. Document mismatch"
                />
                <div className="modal-actions">
                    <button onClick={onClose} className="cancel-button">
                        Back
                    </button>
                    <button
                        onClick={onSubmit}
                        disabled={!comment}
                        className={"submit-button" + (!comment ? " disabled" : "")}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NeedsAttentionModal;
