// src/components/MasterData/CreatePurposeModal.js

import React, { useState, useEffect } from 'react';
// import './CreatePurposeModal.css';

const CreatePurposeModal = ({ onClose, onCreate, onEdit, isEditing, initialData }) => {
    const [purpose, setPurpose] = useState('');
    const [subPurpose, setSubPurpose] = useState('');
    const [subPurposeCode, setSubPurposeCode] = useState('');

    useEffect(() => {
        if (isEditing && initialData) {
            setPurpose(initialData.purpose);
            setSubPurpose(initialData.subPurpose);
            setSubPurposeCode(initialData.subPurposeCode);
        }
    }, [isEditing, initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const purposeData = { purpose, subPurpose, subPurposeCode };
        if (isEditing) {
            onEdit(purposeData);
        } else {
            onCreate(purposeData);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{isEditing ? 'Edit Purpose' : 'Create New Purpose'}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Purpose</label>
                        <select
                            value={purpose}
                            onChange={(e) => setPurpose(e.target.value)}
                            required
                        >
                            <option value="" disabled>Select Purpose</option>
                            <option value="TOURIST">TOURIST</option>
                            <option value="BUSINESS">BUSINESS</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Sub Purpose</label>
                        <input
                            type="text"
                            value={subPurpose}
                            onChange={(e) => setSubPurpose(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Sub Purpose Code</label>
                        <input
                            type="text"
                            value={subPurposeCode}
                            onChange={(e) => setSubPurposeCode(e.target.value)}
                            required
                        />
                    </div>
                    <div className="modal-actions">
                        <button type="button" onClick={onClose} className="cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="create-button">
                            {isEditing ? 'Save Changes' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreatePurposeModal;
