// src/components/UserManagement/UserManagement.js

import React, { useState, useEffect, useContext } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { apiService } from '../../services/apiService';
import './UserManagement.css';
import CreateUserModal from './CreateUserModal';
import { UserContext } from '../../context/UserContext';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const [editingUser, setEditingUser] = useState(null);

    const { user: currentUser } = useContext(UserContext);

    // Fetch users when the component is mounted
    const fetchUsers = async () => {
        try {
            const response = await apiService.get('/internal/admin/user-management/users');
            setUsers(response.users);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const openCreateUserModal = () => {
        setEditingUser(null); // Reset editing user state
        setShowCreateUserModal(true);
    };

    const openEditUserModal = (user) => {
        setEditingUser(user); // Set the user to be edited
        setShowCreateUserModal(true);
    };

    const closeCreateUserModal = () => {
        setShowCreateUserModal(false);
    };

    const handleUserCreatedOrUpdated = () => {
        fetchUsers(); // Refresh the user list after creating or updating a user
        closeCreateUserModal();
    };

    const handleDeleteUser = async (userId) => {
        try {
            let confirmDelete = window.confirm("Are you sure you want to delete this user?");
            if (!confirmDelete)
                return;
            await apiService.delete(`/internal/admin/user-management/user/${userId}`);
            fetchUsers(); // Refresh the user list after deletion
        } catch (error) {
            console.error('Error deleting user:', error);
            alert('Error deleting user. Please try again.');
        }
    };

    if (!currentUser) {
        return null;
    }

    return (
        <div className="user-management-container">
            <h1>Internal Users</h1>
            <button className="create-user-button" onClick={openCreateUserModal}>
                Create New User
            </button>
            {showCreateUserModal && (
                <CreateUserModal
                    onClose={closeCreateUserModal}
                    onCreate={handleUserCreatedOrUpdated}
                    editingUser={editingUser} // Pass the editing user to the modal if in edit mode
                />
            )}
            <table className="user-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Role</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr id={user._id} key={user._id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.role}</td>
                            <td>
                                <FaEdit
                                    className="edit-icon"
                                    onClick={() => openEditUserModal(user)}
                                />
                            </td>
                            <td>
                                {user._id !== currentUser.user._id && (
                                    <FaTrash
                                        className="delete-icon"
                                        onClick={() => handleDeleteUser(user._id)}
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserManagement;
