import React, { useState, useEffect, useRef, startTransition } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import './ActiveApplications.css';
import { FaPlus, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import NewApplicationModal from './NewApplicationModal';
import { backendToFrontEndStatusMapping } from '../../config/constants'


const ActiveApplications = () => {
    const [applications, setApplications] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page')) || 1);
    const limit = 10;
    const [totalCount, setTotalCount] = useState(0);
    const [statusFilter, setStatusFilter] = useState([]);
    const [searchFromDate, setSearchFromDate] = useState('');
    const [searchToDate, setSearchToDate] = useState('');
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFilterPopupVisible, setIsFilterPopupVisible] = useState(false);
    const filterGroupRef = useRef(null);
    const navigate = useNavigate();

    const fetchApplications = async (page, searchQuery = '') => {
        try {
            const response = await apiService.get(`/internal/active-application-groups?page=${page}&limit=${limit}&searchQuery=${searchQuery}&travelStartDateFrom=${searchFromDate}&travelStartDateTo=${searchToDate}`);
            setApplications(response.visaApplicationGroups);
            setTotalCount(response.totalCount);
        } catch (error) {
            console.error('Error fetching applications:', error);
        }
    };

    const updatePhaseFilter = (filter) => {
        if (statusFilter.includes(filter))
            setStatusFilter(statusFilter.filter(phase => phase != filter))
        else
            setStatusFilter([filter, ...statusFilter]);
    }

    useEffect(() => {

        if (searchFromDate || searchToDate || statusFilter.length)
            setIsFilterActive(true)
        else setIsFilterActive(false)
    }, [searchFromDate, searchToDate, statusFilter])

    useEffect(() => {
        setSearchParams({ page: currentPage });
        fetchApplications(currentPage, searchQuery);
    }, [currentPage, searchQuery, searchFromDate, searchToDate]);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / limit)) {
            setCurrentPage((prevPage) => {
                const nextPage = prevPage + 1;
                setSearchParams({ page: nextPage });
                return nextPage;
            });
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => {
                const prevPageNumber = prevPage - 1;
                setSearchParams({ page: prevPageNumber });
                return prevPageNumber;
            });
        }
    };


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleCreateNewApplication = (applicationGroupId) => {
        navigate(`/visa-application-group/${applicationGroupId}`)
        closeModal(); // Close the modal after creation
    };

    const handleRowClick = (applicationGroupId) => {
        navigate(`/visa-application-group/${applicationGroupId}`, { state: { fromPage: currentPage } });
    }

    const toggleFilterPopup = (e) => {
        if (e.target.closest(".filter-popup"))
            return;
        setIsFilterPopupVisible(!isFilterPopupVisible);
    };

    const handleOutsideClick = (event) => {
        if (filterGroupRef.current && !filterGroupRef.current.contains(event.target)) {
            setIsFilterPopupVisible(false);
        }
    };

    useEffect(() => {
        if (isFilterPopupVisible) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isFilterPopupVisible]);


    return (
        <div className="main-container">
            <div className="main-header">
                <h2>Applications</h2>
                <div className="main-header-options">
                    <div className="search-box">
                        <img className="search-input-icon" src="/images/search-icon.png" />
                        <input
                            type="text"
                            placeholder="Type to search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className={"filter-group" + (isFilterActive ? " filter-active" : "")} onClick={toggleFilterPopup} ref={filterGroupRef}>
                        <div className="filter-group-btn"   >
                            <img className="filter-group-icon" src="/images/filters-icon.png" />
                            Filters
                        </div>
                        {isFilterPopupVisible && (
                            <div className="filter-popup">
                                <span className="filter-popup-heading">Filters</span>
                                <div className="phase-filters">
                                    <label>Status</label>
                                    <div className="phase-filter-buttons">
                                        <button onClick={() => updatePhaseFilter('submitted')} className={statusFilter.includes('submitted') ? 'active' : ''}>Submitted</button>
                                        <button onClick={() => updatePhaseFilter('docsPending')} className={statusFilter.includes('docsPending') ? 'active' : ''}>Docs Pending</button>
                                    </div>
                                </div>
                                <div className="travel-date-filter">
                                    <label>Travel Date</label>
                                    <div className="date-filter-pickers">
                                        <input
                                            type="date"
                                            value={searchFromDate}
                                            onChange={(e) => setSearchFromDate(e.target.value)}
                                        />
                                        to&nbsp;
                                        <input
                                            type="date"
                                            value={searchToDate}
                                            onChange={(e) => setSearchToDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <button className="new-app-button" onClick={openModal}>
                        <FaPlus /> New Application
                    </button>
                </div>

            </div>
            <div className='table-wrapper'>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Group Name</th>
                            <th>Travel Agent</th>
                            <th>No. of Travellers</th>
                            <th>Country</th>
                            <th>Visa Type</th>
                            <th>Travel Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.length > 0 ? (
                            applications.map(app => (
                                <tr key={app._id} onClick={() => handleRowClick(app._id)}>
                                    <td className="table-td-center">{app.orderNumber}</td>
                                    <td>{app.groupName}</td>
                                    <td className="table-td-center">{app.travelAgentId.businessName}</td>
                                    <td className="table-td-center">{app.numberOfTravellers}</td>
                                    <td className="table-td-center">{app.visaTypeId.countryId.name}</td>
                                    <td>{app.visaTypeId.visaName}</td>
                                    <td className="table-td-center">{new Date(app.travelDateFrom).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, '-')}</td>
                                    <td>{backendToFrontEndStatusMapping[app.internalStatus]}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="empty-table-td">
                                    <center>
                                        <div className="no-data-message">
                                            No Active Applications
                                        </div>
                                        <button className="new-app-button" onClick={openModal}>
                                            <FaPlus /> Start An Application
                                        </button>
                                    </center>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="pagination-controls">
                <button
                    className="pagination-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <FaChevronLeft /> Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(totalCount / limit)}</span>
                <button
                    className="pagination-button"
                    onClick={handleNextPage}
                    disabled={currentPage === Math.ceil(totalCount / limit)}
                >
                    Next <FaChevronRight />
                </button>
            </div>
            {isModalOpen && (
                <NewApplicationModal
                    onClose={closeModal}
                    onCreate={handleCreateNewApplication}
                />
            )}
        </div >
    );
};

export default ActiveApplications;