import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../services/apiService';
import { toast } from 'react-hot-toast';
import './Login.css';

const Login = () => {
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [sendingOtp, setSendingOtp] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [mobileNumberIsValid, setMobileNumberIsValid] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [otpIsValid, setOtpNumberIsValid] = useState(false);
    const mobileRegex = /[0-9]{10}/;
    const otpRegex = /[0-9]{6}/;

    const navigate = useNavigate();

    const handleSendOtp = () => {
        try {
            setSendingOtp(true);
            const otpPromise = apiService.post('/internal/auth/send-otp', { phone: '+91' + phone });

            toast.promise(otpPromise, {
                loading: 'Sending OTP',
                success: () => {
                    setIsOtpSent(true);
                    setSendingOtp(false);
                    return 'OTP Sent'
                },
                error: (error) => {
                    setSendingOtp(false);
                    return error.response?.data?.error ||
                        'Error sending OTP'
                }
            });

        } catch (error) {
            toast.error('Failed to send OTP. Please try again.');
        }
    };

    const handleVerifyOtp = () => {
        try {
            setLoggingIn(true);
            const loginPromise = apiService.post('/internal/auth/verify-otp', { phone: '+91' + phone, otp });

            toast.promise(loginPromise, {
                loading: 'Verifying OTP',
                success: (res) => {
                    localStorage.setItem('token', res.token);
                    navigate('/');
                    return 'OTP Verification Successful'
                },
                error: (error) => {
                    return error.response?.data?.error || 'Invalid OTP'
                }
            })

        } catch (error) {
            toast.error('Invalid OTP or Login Failed. Please try again.');
        }
    };

    const handleMobileNumberUpdate = (e) => {
        const numberToUpdate = e.target.value;

        if (numberToUpdate.length > 10)
            return;

        if (numberToUpdate.match(mobileRegex)) {
            setMobileNumberIsValid(true);
        } else {
            setMobileNumberIsValid(false);
        }

        setPhone(numberToUpdate);
    }

    const handleOtpUpdate = (e) => {
        const otpToUpdate = e.target.value;

        if (otpToUpdate.length > 6)
            return;

        if (otpToUpdate.match(otpRegex)) {
            setOtpNumberIsValid(true);
        } else {
            setOtpNumberIsValid(false);
        }

        setOtp(otpToUpdate);
    }

    return (
        <div className="login-container">
            <div className="login-left">
                <div className="login-left-content">
                    <img src='/images/login/logo.png' />
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                </div>
            </div>
            <div className="login-right">
                <div className="login-box">
                    <span className='heading'>Login</span>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    {!isOtpSent ? (
                        <div className="input-group">
                            <label>Mobile Number</label>
                            <div className="input-with-prefix">
                                <span class="mobile-prefix">+91</span>
                                <input
                                    type="number"
                                    placeholder="Enter mobile number"
                                    value={phone}
                                    onChange={handleMobileNumberUpdate}
                                    required
                                />
                            </div>
                            <button
                                className="send-otp-button"
                                onClick={handleSendOtp}
                                disabled={!mobileNumberIsValid && sendingOtp}
                            >
                                Send OTP
                            </button>
                        </div>
                    ) : (
                        <div className="input-group">
                            <label>OTP</label>
                            <input
                                type="number"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={handleOtpUpdate}
                                required
                            />
                            <button
                                className="login-button"
                                onClick={handleVerifyOtp}
                                disabled={!otpIsValid && loggingIn}
                            >
                                Login
                            </button>
                        </div>
                    )}
                    <p className="terms-text">By clicking next, you agree to <a href="#">Terms & Conditions</a></p>
                </div>
            </div>
        </div>
    );
};

export default Login;
