import React, { useState } from 'react';
import { apiService } from '../../services/apiService'; // Assuming you have an API service for making HTTP requests
import { toast } from 'react-hot-toast';

const VisaSubmissionModal = ({ onClose, visaApplicationId, onSubmitSuccess, submissionName }) => {
    const [submissionReferenceNumber, setSubmissionReferenceNumber] = useState('');
    const [submissionReceiptFile, setSubmissionReceiptFile] = useState(null);
    const [submissionDate, setSubmissionDate] = useState(() => new Date().toISOString().split('T')[0]);
    const [modeOfSubmission, setModeOfSubmission] = useState('Online');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFileChange = (e) => {
        setSubmissionReceiptFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!submissionReferenceNumber || !submissionDate || !modeOfSubmission || !submissionReceiptFile) {
            toast.error("All fields are required!");
            return;
        }

        const formData = new FormData();
        formData.append('submissionReferenceNumber', submissionReferenceNumber);
        formData.append('submissionDate', submissionDate);
        formData.append('modeOfSubmission', modeOfSubmission);
        formData.append('type', submissionName);
        formData.append('file', submissionReceiptFile);

        setIsSubmitting(true);

        try {
            const visaSubmissionPromise = apiService.upload(`/internal/visa-application/create-submission/${visaApplicationId}`, formData);

            toast.promise(visaSubmissionPromise, {
                loading: "Updating visa submission...",
                success: "Visa submission updated successfully!",
                error: "Error updating visa submission"
            });

            visaSubmissionPromise.then(response => {
                onSubmitSuccess(response);
                onClose();
            });
        } catch (error) {
            console.error("Error submitting visa submission:", error);
            toast.error("Failed to submit visa submission.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Update Visa Submission</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Submission Reference Number</label>
                        <input
                            type="text"
                            value={submissionReferenceNumber}
                            onChange={(e) => setSubmissionReferenceNumber(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Submission Date</label>
                        <input
                            type="date"
                            value={submissionDate}

                            onChange={(e) => setSubmissionDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Mode of Submission</label>
                        <select
                            value={modeOfSubmission}
                            onChange={(e) => setModeOfSubmission(e.target.value)}
                            required
                        >
                            <option value="Online">Online</option>
                            <option value="Physical">Physical</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Upload Receipt Files</label>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept=".pdf,.png,.jpg,.jpeg,.gif"
                            required={true}
                        />
                    </div>
                    <div className="modal-actions">
                        <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
                        <button type="submit" className="submit-button" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VisaSubmissionModal;
