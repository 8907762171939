// src/components/Finance/TransactionRejectionModal.js

import React, { useState } from 'react';
import { apiService } from '../../../services/apiService';
import './ManualTransactions.css';

const TransactionRejectionModal = ({ transaction, onClose, onReject }) => {
    const [rejectionReason, setRejectionReason] = useState('');
    const [error, setError] = useState(null);

    const handleReject = async () => {
        if (!rejectionReason) {
            setError('Rejection reason is required.');
            return;
        }

        try {
            await apiService.post(`/internal/finance/reject-manual-transaction/${transaction._id}`, {
                rejectionReason,
            });
            onReject(); // Notify the parent to refetch transactions
        } catch (error) {
            console.error('Error rejecting transaction:', error);
            setError('Failed to reject transaction. Please try again.');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Reject Transaction</h3>
                {error && <div className="error-message">{error}</div>}
                <div className="form-group">
                    <label>Rejection Reason</label>
                    <textarea
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        required
                    />
                </div>
                <div className="modal-actions">
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="reject-button" onClick={handleReject}>
                        Reject
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TransactionRejectionModal;
